import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const putLikeToFeed = async (id, type) => {
    try {
        const accessToken = storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        
        const response = await axios.put('/api/feed/toggle_like/', null, {
            params: { id: String(id), type: type },
            headers: { Authorization: `Bearer ${accessToken}` }
        });
        
        return response;
    } catch (error) {
        console.error('Error toggling like:', error);
        throw error;
    }
}
