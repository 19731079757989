import React, {useState, useEffect, useCallback} from 'react';
import ResourceTypeIcon from '../ResourceCard/ResourceTypeIcon';
import Loader from '../Loader';
import { fetchResources } from '@linko/shared_utils';

const LinkResourceSuggested = ({ setSelectedResource, currentResource, handleUnlink }) => {
    
    const [resources, setResources] = useState([]);
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(false);
    const [hasMoreResources, setHasMoreResources] = useState(true);
    
    const limit = 10;

    const fetchMyResources = useCallback(async () => {
        if (!isFetchingMoreResources || !hasMoreResources) return;
        
        try {
            await fetchResources(
                limit,
                resourcesOffset,
                [],
                null,
                [],
                resources,
                setResources,
                setResourcesOffset, 
                setHasMoreResources, 
                setIsFetchingMoreResources,
                null,
            );
        } catch (error) {
            console.error('Failed to fetch resources:', error);
        } finally {
            setIsFetchingMoreResources(false);
        }
    }, [isFetchingMoreResources, hasMoreResources, resources, resourcesOffset]);

    useEffect(() => {
        setIsFetchingMoreResources(true);
    }, []);
    
    useEffect(() => {
        fetchMyResources();
    }, [isFetchingMoreResources]);

    const handleScroll = useCallback((event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setIsFetchingMoreResources(true);
        }
    }, []);

    useEffect(() => {
        const tabContainer = document.querySelector('.suggested-resources');
        if (tabContainer) {
            tabContainer.addEventListener('scroll', handleScroll);
            return () => tabContainer.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    const renderResource = (resource, isCurrentResource = false) => (
        <div
            key={resource.id}
            className={`search-results-resource ${isCurrentResource ? 'current-resource' : ''}`}
            style={isCurrentResource ? {backgroundColor:'#effaf5'} : {}}
            onClick={() => !isCurrentResource && setSelectedResource(resource.resource)}
        >
            <ResourceTypeIcon type={isCurrentResource ? resource.type : resource.resource.type} size={16}/>
            <span>{isCurrentResource ? resource.title : resource.resource.title}</span>
            {isCurrentResource && (
                <button onClick={handleUnlink} className="unlink-button">Unlink</button>
            )}
        </div>
    );

    return (
        <div className='suggested-resources'>
            {currentResource && renderResource(currentResource, true)}
            {resources.filter(r => r.resource.id !== currentResource?.id).map(resource => renderResource(resource))}
            {isFetchingMoreResources && <Loader/>}
        </div>
    );
};

export default LinkResourceSuggested;
