import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, LoadingContext } from "../../Context/AppContext";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { handleLogin, handleGoogleLogin } from '@linko/shared_utils';
import { GoogleLogin } from '@react-oauth/google';

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { setIsAuthenticated } = useContext(AuthContext);
    const { setIsLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const [loginStatus, setLoginStatus] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showBetaCodeModal, setShowBetaCodeModal] = useState(false);
    const [betaCode, setBetaCode] = useState('');
    const [betaCodeError, setBetaCodeError] = useState('');
    const [pendingGoogleCredential, setPendingGoogleCredential] = useState(null);
    const [googleLoginState, setGoogleLoginState] = useState({
        isLoading: false,
        error: null,
        requiresBetaCode: false
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Activation link error handling
    useEffect(() => {
        const linkError = localStorage.getItem("linkError");
        if (linkError) {
            setIsError(true);
            setErrorMessage(linkError);
            setTimeout(() => {
                localStorage.removeItem("linkError");
            }, 5000);
        }
    }, []);

    // Activation success notice
    useEffect(() => {
        const successNotice = localStorage.getItem("successNotice");
        if (successNotice) {
            setIsError(false);
            setLoginStatus(successNotice);
            setTimeout(() => {
                localStorage.removeItem("successNotice");
            }, 5000);
        }
    }, []);

    useEffect(() => {
        const sessionExpiredMessage = localStorage.getItem("session_expired");
        if (sessionExpiredMessage) {
            setIsError(true);
            setLoginStatus(sessionExpiredMessage);
            localStorage.removeItem("session_expired");
        }
    }, []);

    const handleGoogleSuccess = async (credentialResponse) => {
        setGoogleLoginState({ isLoading: true, error: null });
        try {
            const result = await handleGoogleLogin(
                {
                    ...credentialResponse,
                    betaCode: null // Initially no beta code
                },
                'web',
                setIsError,
                setLoginStatus,
                (credential) => {
                    setGoogleLoginState({
                        isLoading: false,
                        requiresBetaCode: true
                    });
                    setPendingGoogleCredential(credential);
                    setShowBetaCodeModal(true);
                }
            );
            if (result?.requires_beta_code) {
                setGoogleLoginState({
                    isLoading: false,
                    error: 'Beta code required',
                    requiresBetaCode: true
                });
                setPendingGoogleCredential(credential);
                setShowBetaCodeModal(true);
            } else if (result?.access && result?.refresh) {
                await localStorage.setItem('access_token', result.access);
                await localStorage.setItem('refresh_token', result.refresh);
                setIsAuthenticated(true);
                navigate('/my_linko');
            }
        } catch (error) {
            setGoogleLoginState({
                isLoading: false,
                error: error.response?.data?.message || 'Google login failed'
            });
            setIsError(true);
            setLoginStatus(error.response?.data?.message || 'Google login failed');
        }
    };

    const onLoginPress = async () => {
        try {
            if (email === null || email === undefined || password === null || password === undefined) {
                throw new Error("Email or password is missing");
            }
            await handleLogin(email, password, setIsError, setLoginStatus)
        } catch (error) {
            if (error.message === "Broken pipe") {
                console.error("Connection lost");
            } else {
                console.error(error);
            }
        } 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onLoginPress();
    };

    useEffect(() => {
        if (loginStatus === "Login successful") {
            setIsLoading(true);
            setIsAuthenticated(true);
            navigate("/my_linko");
        }
    }, [loginStatus]);

    const handleBetaCodeSubmit = async () => {
        setGoogleLoginState({ isLoading: true, error: null });
        try {
            const result = await handleGoogleLogin(
                {
                    ...pendingGoogleCredential,
                    betaCode
                },
                'web',
                setIsError,
                setLoginStatus,
                null // No need for beta code callback on resubmission
            );
            
            if (result?.access && result?.refresh) {
                await localStorage.setItem('access_token', result.access);
                await localStorage.setItem('refresh_token', result.refresh);
                setIsAuthenticated(true);
                navigate('/my_linko');
                setShowBetaCodeModal(false);
            }
        } catch (error) {
            setGoogleLoginState({
                isLoading: false,
                error: error.response?.data?.message || 'Beta code verification failed'
            });
            setBetaCodeError(error.response?.data?.message || 'Invalid code');
        } finally {
            setBetaCode('');
        }
    };

    return (
        <div className="login">
            <div className="login-form-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-form-content">
                        <h3 className="welcome-back">Welcome Back!</h3>
                        <p style={{marginBottom:'15px', color:'#257953'}}>{loginStatus}</p>
                        <div className="login-form-group">
                            <label className="login-form-label">Email</label>
                            <input
                                className="login-form-control"
                                placeholder="Enter email"
                                name="email"
                                type="email"
                                value={email}
                                required
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="login-form-group">
                            <label className="login-form-label">Password</label>
                            <div className="password-input">
                                <input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    className="login-form-control"
                                    placeholder="Enter password"
                                    value={password}
                                    autoComplete="current-password"
                                    required
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    style={{ paddingRight: "50px" }}
                                />
                                <div
                                    onClick={togglePasswordVisibility}
                                    className="password-visibility-icon"
                                >
                                    {showPassword ? (
                                        <HiOutlineEyeOff />
                                    ) : (
                                        <HiOutlineEye />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="login-form-footer">
                            <p className='login-status error-notice'>{errorMessage}</p>
                            <div className="login-form-footer-right">
                                <div className="login-form-link">
                                    <p>
                                        Don't have an account?{" "}
                                        <Link to="/register">Register</Link>
                                    </p>
                                    <Link to="/forget_password">
                                        Forgot password?
                                    </Link>
                                </div>
                                <button className="login-button" type="submit">
                                    Log in
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="google-login-container">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                                setIsError(true);
                                setLoginStatus('Google login failed');
                            }}
                        />
                    </div>
                </form>

            </div>
            {showBetaCodeModal && (
                <div className="modal-overlay">
                    <div className="name-change-modal">
                        <div className="modal-header">
                            <h2>Enter an Invitation Code</h2>
                            <button 
                                className="close-button" 
                                onClick={() => {
                                    setShowBetaCodeModal(false);
                                    setPendingGoogleCredential(null);
                                    setBetaCode('');
                                    setGoogleLoginState({ isLoading: false, error: null });
                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="input-group">
                                <label htmlFor="betaCode">INVITATION CODE</label>
                                <input
                                    type="text"
                                    id="betaCode"
                                    value={betaCode}
                                    onChange={(e) => setBetaCode(e.target.value)}
                                    placeholder="Enter invitation code"
                                    className={googleLoginState.error ? 'error' : ''}
                                    disabled={googleLoginState.isLoading}
                                />
                            </div>
                            {googleLoginState.error && (
                                <p className="error-notice" style={{color:'red'}}>
                                    {googleLoginState.error}
                                </p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="linko-button linko-button--cancel" 
                                onClick={() => {
                                    setShowBetaCodeModal(false);
                                    setPendingGoogleCredential(null);
                                    setBetaCode('');
                                    setGoogleLoginState({ isLoading: false, error: null });
                                }}
                                disabled={googleLoginState.isLoading}
                            >
                                Cancel
                            </button>
                            <button 
                                className={`linko-button linko-button--primary ${
                                    googleLoginState.isLoading ? 'loading' : ''
                                }`}
                                onClick={() => {
                                    setGoogleLoginState({ isLoading: true, error: null });
                                    handleBetaCodeSubmit();
                                }}
                                disabled={googleLoginState.isLoading}
                            >
                                {googleLoginState.isLoading ? 'Verifying...' : 'Submit'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Login;
