import React, { useState } from 'react';

const LibrarySubjectFilter = ({
    filterSubject, 
    setFilterSubject,
    activeTab,
    isLoading,
    userSubject,
}) => {

    const normalizedFilterSubjects = filterSubject.map(id => id);

    const resourceTopSubject = userSubject
        ?.filter(item => item.is_linked === true && item.resource_count > 0)
        .sort((a, b) => b.resource_count - a.resource_count);
    const noteTopSubject = userSubject
        ?.filter(item => item.is_linked === true && item.note_count > 0)
        .sort((a, b) => b.note_count - a.resource_count);
    const myTags = userSubject
        ?.filter(item => item.is_linked === false)
        .sort((a, b) => b.resource_count - a.resource_count); 
    const topSubject = activeTab === 'notes' ? noteTopSubject : resourceTopSubject;
    const [showAll, setShowAll] = useState(false);

    const handleSubjectFilterClick = (subjectId) => {
        if (normalizedFilterSubjects.includes(subjectId)) {
            setFilterSubject(normalizedFilterSubjects.filter(k => k !== subjectId));
        } else {
            setFilterSubject([...normalizedFilterSubjects, subjectId]);
        }
    };

    return (
        <div className='library-subject-filter'>
            {myTags?.map((subject) => (
                <div 
                    key={subject.id} 
                    className={`cus-label ${normalizedFilterSubjects.includes(subject.id) ? 'active' : 'inactive'}`} 
                    onClick={() => !isLoading && handleSubjectFilterClick(subject.id)}
                    style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                >
                    {subject.name}
                    {subject[activeTab === 'notes' ? 'note_count' : 'resource_count'] > 0 && 
                        ` · ${subject[activeTab === 'notes' ? 'note_count' : 'resource_count']}`
                    }
                </div>
            ))}
            {topSubject?.slice(0, showAll ? topSubject.length : 10).map((subject) => (
                <div 
                    key={subject.id} 
                    className={`subject-tag ${normalizedFilterSubjects.includes(subject.id) ? 'studied' : 'not-studied'}`} 
                    onClick={() => !isLoading && handleSubjectFilterClick(subject.id)}
                    style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                >
                    {subject.name}
                    {subject[activeTab === 'notes' ? 'note_count' : 'resource_count'] > 0 && 
                        ` · ${subject[activeTab === 'notes' ? 'note_count' : 'resource_count']}`
                    }
                </div>
            ))}
            {topSubject?.length > 10 && ( 
                showAll ? 
                    <button className='show-button' onClick={() => setShowAll(false)}>Show Less</button>
                    :
                    <button className='show-button' onClick={() => setShowAll(true)}>Show All</button>
            )}
        </div>
    );
};

export default LibrarySubjectFilter;