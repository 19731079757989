import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchNotes = async (
  limit,
  notesOffset,
  resource_id,
  note_id,
  notes,
  setNotes,
  setNotesOffset,
  setHasMoreNotes,
  setIsFetchingMoreNotes,
  noteFilterKnowledge = [],
  signal,
) => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    if (signal?.aborted) {
      return;
    }
    
    const headers = { Authorization: `Bearer ${accessToken}` };
    const payload = {
      limit,
      offset: notesOffset,
    };
    if (Array.isArray(noteFilterKnowledge) && noteFilterKnowledge.length > 0) {
      payload.filter_knowledge = noteFilterKnowledge.join(',');
    }
    if (resource_id !== null && resource_id !== undefined) payload.resource_id = resource_id;
    if (note_id !== null && note_id !== undefined) payload.note_id = note_id;

    const apiEndpoint = (note_id !== null && note_id !== undefined) ? `/api/note/${note_id}/related/` : '/api/note/';

    const config = { headers, params: payload };
    if (signal) config.signal = signal;

    const response = await axios.get(apiEndpoint, config);

    if (response.data && Array.isArray(response.data.results)) {
      const existingNotes = Array.isArray(notes) ? notes : [];
      const newNotes = response.data.results.filter(newNote => !existingNotes.some(note => note.id === newNote.id));
      
      // Update the notes state
      setNotes(prevNotes => {
        const updatedNotes = [...prevNotes, ...newNotes];
        return updatedNotes;
      });

      if (newNotes.length > 0) {
        setNotesOffset(prevOffset => prevOffset + newNotes.length);
      }
      setHasMoreNotes(newNotes.length === limit);
    } else {
      console.warn('Unexpected response format:', response.data);
    }
    setIsFetchingMoreNotes(false);
  } catch (error) {
    if (axios.isCancel(error)) {
      setIsFetchingMoreNotes?.(true);
    } else {
      console.error('Failed to fetch notes:', error);
      if (error.config) {
        console.error('Request config:', error.config);
      }
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      // Don't set isFetchingMoreNotes to false for other errors
    }
  }
};

export const fetchRevisitData = async (setRevisitSubject, setRevisitNote, setIsShufflingSubject) => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }

    const headers = { Authorization: `Bearer ${accessToken}` };
    const config = { headers };
    const response = await axios.get('/api/note/revisit/', config);

    if (response.data) {
      setRevisitSubject({
        id: response.data.id,
        name: response.data.name,
        is_linked: response.data.is_linked,
        qa: response.data.qa
      });
      setRevisitNote(response.data.notes);
      setIsShufflingSubject(false);
      return response; 
    } else {
      console.warn('Unexpected response format:', response.data);
    }
  } catch (error) {
    console.error('Failed to fetch revisit data:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
  } finally {
    setIsShufflingSubject(false);
  }
};
