import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchSummary = async ({resourceId, subjectId}) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        const headers = { Authorization: `Bearer ${accessToken}` };
        const queryParam = resourceId ? `resource_id=${resourceId}` : `subject_id=${subjectId}`;
        const response = await axios.get(`/api/note/summarize/?${queryParam}`, { headers });
        return response;
    } catch (error) {
        return null;
    }
};