import React, { useState, useRef, useContext, useEffect } from 'react';
import { UserContext } from '../../Context/AppContext';
import { HomePageContext } from '../../Context/HomePageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../ToastNotification';
import { useModal } from '../../Context/ModalContext';
// Components
import LinkResourceModal from './LinkResourceModal';
// Icons
import { FaRegTrashAlt, FaRegShareSquare } from "react-icons/fa";
import { MdMoreVert } from "react-icons/md";
import { PiNotePencilBold } from "react-icons/pi";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { TbCards } from "react-icons/tb";
import { deleteNote, deleteFileFromS3 } from '@linko/shared_utils';
import { PiDownloadSimpleBold } from "react-icons/pi";
import { generateNoteImage } from '../../utils/noteImageGenerator';

// Function to extract image URLs from markdown content
const extractImageUrls = (markdownContent) => {
  if (!markdownContent) return [];
  
  const imageUrls = new Set();
  const imageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g;
  let match;
  
  while ((match = imageRegex.exec(markdownContent)) !== null) {
    const url = match[2];
    // Extract the key from the S3 URL
    const keyMatch = url.match(/\/uploads\/[^/]+\/[^?]+/);
    if (keyMatch) {
      imageUrls.add(keyMatch[0]);
    }
  }
  
  return Array.from(imageUrls);
};

const NoteCardActions = ({
    note, 
    noteId,
    onEditClick, 
    onDelete, 
    updateSingleNote,
    noteResource,
    setNoteResource,
    myNotePage,
}) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { addToast, setPersistentMessage } = useToast();
    const { requestConfirm } = useModal();
    const [linkCopied, setLinkCopied] = useState(false);
    const { fetchUserSubjectData, fetchUserInfo } = useContext(UserContext);
    const { removeItemFromHomePage } = useContext(HomePageContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isCurrentNotePage = location.pathname === `/n/${noteId}`;
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = (event) => {
        event.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDeleteNote = async () => {
        requestConfirm(
            'Are you sure you want to delete this note?',
            async () => {
                try {
                    // Extract image URLs from note content
                    const imageUrls = extractImageUrls(note?.note);
                    
                    // Delete each image from S3
                    if (imageUrls.length > 0) {
                        for (const imageUrl of imageUrls) {
                            try {
                                await deleteFileFromS3(imageUrl);
                            } catch (error) {
                                console.error('Error deleting image:', imageUrl, error);
                            }
                        }
                    }

                    // Delete the note
                    await deleteNote(noteId);
                    
                    if (myNotePage && !updateSingleNote) {
                        navigate(-1);
                        setPersistentMessage('Note deleted.', {appearance: 'success'});
                    } else {
                        onDelete(note.id);
                        addToast('Note deleted.', {appearance: 'success'});
                    }
                    removeItemFromHomePage(noteId, 'note');
                    await fetchUserSubjectData();
                    await fetchUserInfo();
                } catch (error) {
                    console.error('Error in handleDeleteNote:', error);
                    addToast('Failed to delete note.', {appearance: 'error'});
                }
            },
            null,
            'Delete'
        );
    }

    const copyNoteLink = () => {
        setLinkCopied(true);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(`https://www.linko.study/n/${noteId}`)
            .then(() => {
                addToast('Note link copied!', {appearance: 'success'});
                setTimeout(() => setLinkCopied(false), 2000);
            })
            .catch(err => {
              console.error('Error writing to clipboard', err);
              addToast('Failed to copy profile link', {appearance: 'error'});
              setTimeout(() => setLinkCopied(false), 2000);
            });
        } else {
            console.error('Clipboard API not supported');
            addToast('Failed to copy profile link', {appearance: 'error'});
            setTimeout(() => setLinkCopied(false), 2000);
        }
    }

    const closeModal = () => setIsModalOpen(false);

    const downloadNote = async () => {
        try {
            const imageDataUrl = await generateNoteImage(note, noteId);
            
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = imageDataUrl;
            link.download = `Linko_note_${noteId}.png`;
            
            // Trigger download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            addToast('Note downloaded successfully', {appearance: 'success'});
        } catch (error) {
            console.error('Error downloading note:', error);
            addToast('Failed to download note', {appearance: 'error'});
        }
    };

    return ( 
        <div>
            <div style={{height:'fit-content'}}>
                <MdMoreVert className="MoreTabWLink" onClick={toggleDropdown}/>
                {isDropdownOpen && (
                    <div ref={dropdownRef} className='card-action-dropdown' onClick={(e) => e.stopPropagation()}>
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            onEditClick();
                            setIsDropdownOpen(false);
                        }}>
                            <PiNotePencilBold className='card-action-icon' />
                            <p>Edit</p>
                        </div>
                        {!isCurrentNotePage && (
                            <div className='card-action-group' onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/n/${noteId}`);
                                setIsDropdownOpen(false);
                            }}>
                                <TbCards className='card-action-icon' />
                                <p>Related notes</p>
                            </div>
                        )}
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setIsDropdownOpen(false);
                        }}>
                            <MdOutlineLibraryAdd className='card-action-icon' />
                            <p>Link resource</p>
                        </div>
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            copyNoteLink();
                            setIsDropdownOpen(false);
                        }}>
                            <FaRegShareSquare className='card-action-icon share' color={linkCopied ? '#257953' : '#999999'}/>
                            {linkCopied ? <p style={{color:'#257953'}}>Link copied</p> : <p>Share</p>}
                        </div>
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            downloadNote();
                            setIsDropdownOpen(false);
                        }}>
                            <PiDownloadSimpleBold className='card-action-icon'/>
                            <p>Download</p>
                        </div>
                        <div className='card-action-group delete-group' onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteNote(note.id);
                            setIsDropdownOpen(false);
                        }}>
                            <FaRegTrashAlt className='card-action-icon delete-icon'/>
                            <p>Delete</p>
                        </div>
                    </div>
                )}
            </div>
            {isModalOpen && (
                <LinkResourceModal 
                    onClose={closeModal} 
                    updateSingleNote={updateSingleNote}
                    noteResource={noteResource}
                    setNoteResource={setNoteResource} 
                    noteId={noteId}/>
            )}
        </div>
    );
};

export default NoteCardActions;
