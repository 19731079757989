import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AddToLinko from '../NavAndFooter/AddToLinko';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { UserContext } from '../../Context/AppContext';

const NoContentPlaceholder = ({ activeTab, limited = false }) => {
    const location = useLocation();
    const { userInfo } = useContext(UserContext);

    if (location.pathname.startsWith('/my_linko') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5' >
                <div className='no-content-placeholder'>
                    <h1>Hey {userInfo?.first_name}, nice to see you here!</h1>
                    <p style={{marginBottom:'12px'}}>At Linko, we believe learning is a lifelong journey—a path of discovery, growth, and endless possibilities. </p>
                    <p style={{marginBottom:'12px'}}>Our mission is to equip you with a simple yet powerful tool to enrich your journey. </p>
                    <p style={{marginBottom:'12px'}}>With Linko, you can:</p>
                    <ul style={{lineHeight:'1.5', listStyleType:'none', padding:0, fontSize:'16px'}}>
                        <li style={{marginBottom:'12px', marginLeft:'10px'}}>✍️ Capture your thoughts</li>
                        <li style={{marginBottom:'12px', marginLeft:'10px'}}>📚 Collect resources that inspire you</li>
                        <li style={{marginBottom:'12px', marginLeft:'10px'}}>🌟 Keep things organized in a structured way without the hassle</li>
                    </ul>
                    <p style={{marginBottom:'12px'}}>Let's get started by adding your first resource or note!</p>
                    <AddToLinko />
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/my_linko') && activeTab === 'revisit') {
        return (
            <div className='linko-card mt-5'>
                <p style={{textAlign:'center'}}>Take at least 1 note to unlock Ignite.</p>
            </div>
        );
    }
    
    if (location.pathname.startsWith('/my_linko') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🔍 Discover Amazing Learners!</h1>
                    <p>Follow other curious minds and get inspired by their learning journeys. Your next "aha!" moment might come from someone else's insights!</p>
                </div>
            </div>
        );
    }
     
    if (location.pathname.startsWith('/s/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🌱 Be a Pioneer!</h1>
                    <p>Looks like you're early to the party - no one in your network has shared their learning journey for this subject yet.</p>
                    <p>Why not be the first to share your insights and inspire others?</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/s/') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>📚 Start Your Journey!</h1>
                    <p>Ready to dive into this subject? Add your first learning resource or note!</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>✍️ Capture Your Thoughts!</h1>
                    <p>Your insights are valuable - start taking notes to record and reflect.</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🎯 Lead the Way!</h1>
                    <p>You're the first one here! Share your learning experience and inspire others who are interested in this resource.</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/n/') && activeTab === 'notes') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🔄 Connect the Dots!</h1>
                    <p>Add more notes to discover fascinating connections in your learning journey.</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/profile/') && !limited) {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>👋 New Explorer!</h1>
                    <p>This learner hasn't shared their journey yet. Check back later to see what they're discovering!</p>
                </div>
            </div>
        );
    } 

    if (limited) {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <p>Follow this learner to see their full learning journey.</p>
                </div>
            </div>
        );
    }
};

export default NoContentPlaceholder;