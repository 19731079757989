import QRCode from 'qrcode';
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import '@fontsource/merriweather';
import ResourceTypeIcon from '../Components/ResourceCard/ResourceTypeIcon.jsx';

export const generateNoteImage = async (note, noteId) => {
    const fontStyle = document.createElement('style');
    // fontStyle.textContent = `
    //     @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap');
    // `;
    document.head.appendChild(fontStyle);

    const container = document.createElement('div');
    container.style.width = '600px';
    container.style.backgroundColor = '#effaf5';
    container.style.padding = '20px';
    container.style.position = 'absolute';
    container.style.left = '-9999px';
    // container.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
    
    // Create header container with flex layout
    const header = document.createElement('div');
    header.style.display = 'flex';
    header.style.justifyContent = 'space-between';
    header.style.alignItems = 'center';
    header.style.marginBottom = '20px';
    header.style.paddingLeft = '20px';
    
    // Create left side container for logo and author
    const leftSide = document.createElement('div');
    
    // Add text logo
    const logoText = document.createElement('div');
    logoText.textContent = 'Linko';
    logoText.style.fontSize = '32px';
    logoText.style.fontWeight = '600';
    // logoText.style.fontStyle = 'italic';
    logoText.style.color = '#48c78e';
    // logoText.style.fontFamily = 'Merriweather, sans-serif';
    leftSide.appendChild(logoText);
    
    // Add author if available
    if (note.user?.first_name && note.user?.last_name) {
        const author = document.createElement('p');
        author.textContent = `By ${note.user.first_name} ${note.user.last_name}`;
        author.style.fontStyle = 'italic';
        author.style.fontSize = '18px';
        author.style.fontWeight = '600';
        author.style.marginTop = '5px';
        author.style.marginBottom = '5px';
        author.style.color = '#333';
        leftSide.appendChild(author);
    }
    
    // Add date under author
    const date = document.createElement('p');
    date.textContent = `${new Date(note.create_time).toLocaleDateString()}`;
    date.style.fontSize = '14px';
    date.style.color = '#666';
    date.style.fontStyle = 'italic';
    leftSide.appendChild(date);
    
    // Add left side to header
    header.appendChild(leftSide);
    
    // Generate and add QR code to header
    const qrCodeUrl = `https://www.linko.study/n/${noteId}`;
    const qrCodeDataUrl = await QRCode.toDataURL(qrCodeUrl, {
        width: 100,
        margin: 0,
        color: {
            dark: "#257953",
            light: "#effaf5" // Match the background color
        }
    });

    // Create QR code wrapper
    const qrCodeWrapper = document.createElement('div');
    qrCodeWrapper.style.backgroundColor = '#effaf5';
    qrCodeWrapper.style.padding = '15px';
    qrCodeWrapper.style.borderRadius = '10px';

    const qrCode = document.createElement('img');
    qrCode.src = qrCodeDataUrl;
    qrCode.style.width = '100px';
    qrCode.style.display = 'block';
    
    qrCodeWrapper.appendChild(qrCode);
    header.appendChild(qrCodeWrapper);
    
    // Add header to container
    container.appendChild(header);

    // Create the content structure
    const content = document.createElement('div');
    content.style.backgroundColor = 'white';
    content.style.padding = '20px';
    content.style.borderRadius = '10px';
    content.style.boxShadow = '0px 0px 20px 10px rgba(0, 0, 0, 0.1)';
    
    // Add title
    const title = document.createElement('h1');
    title.textContent = note.title;
    title.style.fontSize = '24px';
    title.style.fontWeight = '600';
    title.style.lineHeight = '24px';
    title.style.marginBottom = '15px';
    title.style.color = '#333333';
    content.appendChild(title);
    
    // Add note content using ReactMarkdown
    const markdownContent = document.createElement('div');
    markdownContent.style.minHeight = '50px';
    markdownContent.style.width = '100%';
    markdownContent.style.color = '#333333';
    markdownContent.style.fontSize = '16px';
    markdownContent.style.fontWeight = '400';
    markdownContent.style.lineHeight = '1.5';
    markdownContent.style.marginBottom = '5px';
    
    // Add CSS styles for markdown elements
    const style = document.createElement('style');
    style.textContent = `
        .markdown-content p {
            margin: 10px 0;
            color: #333333;
        }
        
        .markdown-content ul, .markdown-content ol {
            padding-left: 20px;
            margin: 10px 0 10px 10px;
        }
        
        .markdown-content li {
            margin-bottom: 5px;
        }
        
        .markdown-content ul > li {
            list-style-type: disc;
        }
        
        .markdown-content ul > li > ul > li {
            list-style-type: circle;
        }
        
        .markdown-content ul > li > ul > li > ul > li {
            list-style-type: circle;
        }
        
        .markdown-content ul > li > ul > li > ul > li > ul > li {
            list-style-type: circle;
        }
        
        .markdown-content ul > li > ul > li > ul > li > ul > li > ul > li {
            list-style-type: circle;
        }
        
        // Styles for ordered lists
        .markdown-content ol > li {
            list-style-type: decimal;
        }
        
        .markdown-content ol > li > ol > li {
            list-style-type: lower-alpha;
        }
        
        .markdown-content ol > li > ol > li > ol > li {
            list-style-type: lower-roman;
        }
        
        .markdown-content ol > li > ol > li > ol > li > ol > li {
            list-style-type: decimal;
        }
        
        .markdown-content ol > li > ol > li > ol > li > ol > li > ol > li {
            list-style-type: lower-alpha;
        }
        
        .markdown-content blockquote {
            border-left: 5px solid #eee;
            color: #666;
            font-style: italic;
            margin: 16px 0 !important;
            padding: 10px 20px !important;
        }
        
        .markdown-content blockquote p {
            margin: 0 !important;
        }
        
        .markdown-content h2 {
            font-size: 18px;
            margin: 8px 0;
            font-weight: 600;
        }
    `;
    document.head.appendChild(style);
    
    // Use the same markdown rendering as in NoteCard
    const MarkdownComponent = () => (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className="markdown-content"
            components={{
                em: ({node, ...props}) => <i {...props} />,
                strong: ({node, ...props}) => <strong style={{fontWeight: '600'}} {...props} />,
                u: ({node, ...props}) => <u {...props} />,
                pre: ({node, ...props}) => <pre {...props} />,
                blockquote: ({node, ...props}) => (
                    <blockquote {...props} />
                ),
                h1: ({node, ...props}) => <h1 {...props} />,
                h2: ({node, ...props}) => <h2 {...props} />,
                ol: ({node, ordered, depth, ...props}) => (
                    <ol className={`list-depth-${depth}`} {...props} />
                ),
                ul: ({node, ordered, depth, ...props}) => (
                    <ul className={`list-depth-${depth}`} {...props} />
                ),
                li: ({node, ...props}) => <li {...props} />,
                p: ({node, ...props}) => <p {...props} />,
                a: ({node, ...props}) => (
                    <a target="_blank" rel="noopener noreferrer" {...props} />
                ),
                img: ({node, ...props}) => {
                    const src = props.src || (props.node && props.node.properties && props.node.properties.src);
                    return <img src={src} alt="" style={{maxWidth: '90%', height: 'auto'}} />;
                },
            }}
        >
            {note.note}
        </ReactMarkdown>
    );
    
    markdownContent.innerHTML = ReactDOMServer.renderToString(<MarkdownComponent />);
    content.appendChild(markdownContent);
    
    // Add metadata
    const metadata = document.createElement('div');
    metadata.style.marginTop = '20px';
    
    if (note.knowledge && Array.isArray(note.knowledge)) {
        const knowledgeContainer = document.createElement('div');
        knowledgeContainer.style.display = 'flex';
        knowledgeContainer.style.flexWrap = 'wrap';
        knowledgeContainer.style.gap = '8px';
        knowledgeContainer.style.marginBottom = '10px';

        note.knowledge.forEach(knowledge => {
            if (knowledge.is_linked) {
                const subjectWrapper = document.createElement('div');
                subjectWrapper.style.display = 'flex';
                subjectWrapper.style.alignItems = 'center';
                subjectWrapper.style.width = 'fit-content';
                subjectWrapper.style.height = '26px';
                subjectWrapper.style.padding = '5px 10px';
                subjectWrapper.style.borderRadius = '13px';
                subjectWrapper.style.backgroundColor = '#effaf5';
                
                const subject = document.createElement('p');
                subject.textContent = knowledge.name;
                subject.style.fontSize = '14px';
                subject.style.color = '#257953';
                subjectWrapper.appendChild(subject);
                knowledgeContainer.appendChild(subjectWrapper);
            }
        });

        if (knowledgeContainer.children.length > 0) {
            metadata.appendChild(knowledgeContainer);
        }
    }

    // Add resource section if exists
    if (note.resource && note.resource.length > 0) {
        const resourceSection = document.createElement('div');
        resourceSection.style.display = 'flex';
        resourceSection.style.justifyContent = 'flex-start';
        resourceSection.style.gap = '15px';
        resourceSection.style.marginTop = '20px';
        resourceSection.style.backgroundColor = 'transparent';

        // Add resource type if exists
        if (note.resource[0].type) {
            const iconContainer = document.createElement('div');
            iconContainer.style.paddingTop = '2px';
            const iconString = ReactDOMServer.renderToString(
                ResourceTypeIcon({type: note.resource[0].type, size: 16, color: '#999999'})
            );
            iconContainer.innerHTML = iconString;
            resourceSection.appendChild(iconContainer);
        }

        const titleContainer = document.createElement('div');
        titleContainer.style.flex = '1';
        titleContainer.style.display = 'flex';
        titleContainer.style.flexDirection = 'column';

        // Add resource title
        if (note.resource[0].title) {
            const resourceTitle = document.createElement('div');
            resourceTitle.textContent = note.resource[0].title;
            resourceTitle.style.fontSize = '14px';
            resourceTitle.style.fontWeight = '600';
            resourceTitle.style.color = '#333333';
            resourceTitle.style.lineHeight = '1.4';
            titleContainer.appendChild(resourceTitle);
        }

        // Add resource metadata (author and date)
        if (note.resource[0].author) {
            const author = document.createElement('div');
            author.textContent = note.resource[0].author;
            author.style.fontSize = '12px';
            author.style.fontWeight = '400';
            author.style.color = '#999999';
            titleContainer.appendChild(author);
        }

        resourceSection.appendChild(titleContainer);
        metadata.appendChild(resourceSection);
    }
    
    content.appendChild(metadata);
    
    // Add the content to container
    container.appendChild(content);
    document.body.appendChild(container);
    
    // Wait for QR code image to load
    await Promise.all([
        new Promise(resolve => {
            if (qrCode.complete) resolve();
            else qrCode.onload = resolve;
        })
    ]);
    
    // Capture the content as an image
    try {
        const canvas = await html2canvas(container, {
            backgroundColor: '#effaf5',
            scale: 2, 
            useCORS: true,
            logging: false,
        });
        
        document.body.removeChild(container);
        document.head.removeChild(style);
        document.head.removeChild(fontStyle);
        return canvas.toDataURL('image/png');
    } catch (error) {
        document.body.removeChild(container);
        document.head.removeChild(style);
        document.head.removeChild(fontStyle);
        throw error;
    }
}; 