import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import BackButton from '../../Components/BackButton';
import TabDataWrapper from '../../Components/ContentTabs/TabDataWrapper';
import SubjectPageHeader from './SubjectPageHeader';
import SubjectRel from './SubjectRel';
import RecentlyAdded from '../HomePage/RecentlyAdded';
import { fetchSubjectId, useWindowSize, fetchRelatedSubjects } from '@linko/shared_utils';
import WholeScreenModal from '../../Components/WholeScreenModal';
import CustomTagRel from './CustomTagRel';
import SubjectDescription from './SubjectDescription';
import SummaryCard from '../../Components/SummaryCard/SummaryCard';

const RightSideContent = ({ 
  publicField, 
  subjectName, 
  subjectId, 
  description, 
  setDescription, 
  noteCount, 
  resourceCount,
  broaderField,
  siblingFields,
  childFields,
  relatedSubjects,
  topRelatedSubjects,
  topUsers,
  newSubjectName,
  setNewSubjectName,
  setEditTag,
  editTag 
}) => {
  return publicField ? (
    <>
      <SubjectDescription 
        publicField={publicField} 
        subjectName={subjectName} 
        description={description} 
        noteCount={noteCount} 
        resourceCount={resourceCount}
      />
      <SubjectRel 
        broaderField={broaderField}
        siblingFields={siblingFields} 
        childFields={childFields} 
        subjectId={subjectId}
      />
      <RecentlyAdded 
        subjectId={subjectId} 
        publicField={publicField}
      />
    </>
  ) : (
    <>
      <SubjectDescription 
        publicField={publicField}
        subjectName={subjectName} 
        subjectId={subjectId}
        description={description} 
        setDescription={setDescription}
        noteCount={noteCount} 
        resourceCount={resourceCount}
        newSubjectName={newSubjectName}
        setNewSubjectName={setNewSubjectName}
        setEditTag={setEditTag}
        editTag={editTag}
      />
      <CustomTagRel relatedSubjects={relatedSubjects}/>
      <RecentlyAdded topRelatedSubjects={topRelatedSubjects}/>
    </>
  );
};

const SubjectPage = () => {

  const {subjectName} = useParams();
  const location = useLocation();
  const [subjectId, setSubjectId] = useState(null);
  const [newSubjectName, setNewSubjectName] = useState(subjectName);
  const [description, setDescription] = useState('');
  const [broaderField, setBroaderField] = useState([]);
  const [siblingFields, setSiblingFields] = useState([]);
  const [childFields, setChildFields] = useState([]);
  const [publicField, setPublicField] = useState(null);

  const [relatedSubjects, setRelatedSubjects] = useState([]);
  const [topRelatedSubjects, setTopRelatedSubjects] = useState([]);

  const [noteCount, setNoteCount] = useState(null);
  const [resourceCount, setResourceCount] = useState(null);
  const [topUsers, setTopUsers] = useState([]);
  const [editTag, setEditTag] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [library, setLibrary] = useState([]);

  const { width } = useWindowSize();

  const fetchSubjectInfo = useCallback(async () => {
    const isMyTag = location.pathname.includes('/my/');
    try {
      const subjectInfo = await fetchSubjectId(subjectName, isMyTag);
      setSubjectId(subjectInfo.subjectId);
      setDescription(subjectInfo.description);
      setPublicField(subjectInfo.publicField);
      setBroaderField(subjectInfo.broaderField);
      setSiblingFields(subjectInfo.siblingFields);
      setChildFields(subjectInfo.childFields);
      setNoteCount(subjectInfo.noteCount);
      setResourceCount(subjectInfo.resourceCount);
      setTopUsers(subjectInfo.topUsers);
    } catch (error) {
      console.error('Error fetching subject info:', error);
    }
  }, [subjectName, location.pathname]);

  useEffect(() => {
    setSubjectId(null);
    setDescription('');
    setPublicField(null);
    setBroaderField([]);
    setSiblingFields([]);
    setChildFields([]);
    setNoteCount(null);
    setResourceCount(null);
    setTopUsers([]);
    fetchSubjectInfo();
  }, [subjectName, fetchSubjectInfo]);

  useEffect(() => {
    const fetchSubjects = async () => {
        try {
            const response = await fetchRelatedSubjects(subjectId);
            setRelatedSubjects(response);
            const topFiveIds = response.slice(0, 5).map(subject => subject.id);
            setTopRelatedSubjects(topFiveIds);
        } catch (err) {
            console.error('Error fetching related subjects:', err);
        }
    };
    if (subjectId && !publicField) {
      fetchSubjects();
    }
  }, [subjectId, publicField]);

  useEffect(() => {
    setShowTextArea(false);
  }, [location.pathname]);

  if (!subjectId) return null;

  return (
    <div className='page-container subject-page'>
      <div className='page-right'>
        <BackButton />
        {width <= 1089 &&
          <SubjectPageHeader
            subjectName={subjectName}
            publicField={publicField}
            subjectId={subjectId}
            description={description}
            setDescription={setDescription}
            setIsModalOpen={setIsModalOpen}
            noteCount={noteCount}
            resourceCount={resourceCount}
          />
        }
        <SummaryCard subjectId={subjectId} />
        <TabDataWrapper 
          noteHint={'Take a note under this field'}
          showTextArea={showTextArea}
          setShowTextArea={setShowTextArea}
          subjectId={subjectId} 
          publicField={publicField}
          library={library}
          setLibrary={setLibrary}
        />
      </div>
      
      <div className='home-page-right'>
        <RightSideContent 
          publicField={publicField}
          subjectName={subjectName}
          subjectId={subjectId}
          description={description}
          setDescription={setDescription}
          noteCount={noteCount}
          resourceCount={resourceCount}
          broaderField={broaderField}
          siblingFields={siblingFields}
          childFields={childFields}
          relatedSubjects={relatedSubjects}
          topRelatedSubjects={topRelatedSubjects}
          topUsers={topUsers}
          newSubjectName={newSubjectName}
          setNewSubjectName={setNewSubjectName}
          setEditTag={setEditTag}
          editTag={editTag}
        />
      </div>

      <WholeScreenModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div style={{width:'380px'}}>
          <RightSideContent 
            publicField={publicField}
            subjectName={subjectName}
            subjectId={subjectId}
            description={description}
            setDescription={setDescription}
            noteCount={noteCount}
            resourceCount={resourceCount}
            broaderField={broaderField}
            siblingFields={siblingFields}
            childFields={childFields}
            relatedSubjects={relatedSubjects}
            topRelatedSubjects={topRelatedSubjects}
            topUsers={topUsers}
            newSubjectName={newSubjectName}
            setNewSubjectName={setNewSubjectName}
            setEditTag={setEditTag}
            editTag={editTag}
          />
        </div>
      </WholeScreenModal>
    </div>
  );
};

export default SubjectPage;
