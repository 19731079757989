import React, { useState, useEffect } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { fetchSummary } from '@linko/shared_utils';

const SummaryCard = ({ resourceId, subjectId }) => {
    const [summary, setSummary] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showFullSummary, setShowFullSummary] = useState(false);

    useEffect(() => {
        const getSummary = async () => {
            setIsLoading(true);
            if (resourceId || subjectId) {
                const response = await fetchSummary({resourceId, subjectId});
                if (response.data) {
                    setSummary(response.data);
                } else {
                    setError(response.error);
                }
            }
            setIsLoading(false);
        }
        getSummary();
    }, [resourceId, subjectId]);

    if (isLoading) {
        return ;
    }

    if (error || !summary) {
        return null;
    }

    const truncatedSummary = summary.summary.split('#### Connections and Themes')[0];

    return (
        <div className={`summary-card ${subjectId ? 'subject-summary' : ''}`}>
            <div className="summary-header">
                <span className="summary-title">AI summary</span>
            </div>
            <div className="summary-content">
                <ReactMarkdown>
                    {showFullSummary ? summary.summary : truncatedSummary}
                </ReactMarkdown>
            </div>
            {summary.summary.includes('#### Connections and Themes') && (
                <button 
                    onClick={() => setShowFullSummary(!showFullSummary)} 
                    className="summary-expand-button"
                >
                    {showFullSummary ? 'Show less' : 'Show more'}
                    {showFullSummary ? <FaAngleUp /> : <FaAngleDown />}
                </button>
            )}
        </div>
    );
};

export default SummaryCard; 