import React, {useContext} from "react";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import CustomLink from "../CustomLink";

function HalfFooter() {
    const { safeNavigate } = useContext(NavigationPromptContext);

    return (
        <div id="half-footer">
            <CustomLink to="https://linko.featurebase.app/" className='footer-link'>Feedback</CustomLink>
            {/* <CustomLink to="https://linko.featurebase.app/changelog" className='footer-link'>Changelog</CustomLink> */}
            <CustomLink to="/term_of_service" className='footer-link'>Terms</CustomLink>
            <CustomLink to="/privacy_policy" className='footer-link'>Privacy</CustomLink>
        </div>
    );
}

export default HalfFooter;
