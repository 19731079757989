import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import { AppContextProvider } from './Context/AppContext';
import { ModalProvider } from './Context/ModalContext';
import { NavigationProvider } from './Context/NavigationContext';
import { NavigationPromptProvider } from './Context/NavigationPromptContext';
import { ToastProvider } from './Components/ToastNotification';
import { HomePageProvider } from './Context/HomePageContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './Stylesheets/index.scss';
import PrivateRoute from './Context/PrivateRoute';
import NavBar from './Components/NavAndFooter/NavBar';
import Footer from './Components/NavAndFooter/Footer';
// Auth pages
import Login from './Pages/AuthPages/Login';
import Register from './Pages/AuthPages/Register';
import CongratsRegistered from './Pages/AuthPages/CongratsRegistered';
import ForgetPassword from './Pages/AuthPages/ForgetPassword';
import ActivateAccount from './Pages/AuthPages/ActivateAccount';
import ResetPassword from './Pages/AuthPages/ResetPassword';
// App pages
import MyLibrary from './Pages/MyLibrary/MyLibrary';
import ResourcePage from './Pages/ResourcePage/ResourcePage';
import NotePage from './Pages/NotePage/NotePage';
import NewNotePage from './Pages/NotePage/NewNotePage';
import HomePage from './Pages/HomePage/HomePage';
import SubjectPage from './Pages/SubjectPage/SubjectPage';
import SettingPage from './Pages/AccountSettingPage/SettingPage';
import AGLandingPage from './Pages/LandingPage/AGLandingPage';
import AccountPage from './Pages/AccountSettingPage/AccountPage';
import NotificationPage from './Pages/AccountSettingPage/NotificationPage';
// Public pages·
import PublicProfile from './Pages/PublicProfilePages/PublicProfile';
// Policy pages
import PrivacyPolicy from './Pages/PolicyPages/PrivacyPolicy';
import TermOfService from './Pages/PolicyPages/TermOfService';
import CookiePolicy from './Pages/PolicyPages/CookiePolicy';
import KnowledgeGraphExplained from './Pages/PolicyPages/KnowledgeGraphExplained';

// Importing Google Fonts
// import { injectGlobal } from 'styled-components';
// injectGlobal`
//   @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
//   body {
//     font-family: 'Work Sans', sans-serif;
//   }
// `;

import BrowserRouter from './Components/BrowserRouter';

function App() {
  return (
    <Router>
        <NavigationProvider>
          <ModalProvider>
            <NavigationPromptProvider>
              <ToastProvider>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}>
                  <AppContextProvider>
                    <HomePageProvider>
                      <div id="all-content">
                        <NavBar/>       
                        <div id='mainContent'>
                        <Routes>
                          <Route path="/" element={<AGLandingPage />} />
                          <Route path='/*' element={<Navigate to='/my_linko' />} />
                          {/* Auth Routes */}
                          <Route path="/activate/:uidb64/:token" element={<ActivateAccount />} />
                          <Route path="/reset/:uidb64/:token" element={<ResetPassword />} />
                          <Route path="/login" element={<Login />}/>
                          <Route path="/register" element={<Register />} />
                          <Route path="/thank_you_for_registering" element={<CongratsRegistered />} />
                          <Route path="/forget_password" element={<ForgetPassword />} />
                          <Route path="/reset_password/:uidb64/:token" element={<ResetPassword />} />
                          {/* App Routes */}
                          <Route path="/my_library/*" element={<PrivateRoute><MyLibrary /></PrivateRoute>} />
                          <Route path="/r/:id" element={<PrivateRoute><ResourcePage /></PrivateRoute>} />
                          <Route path="/my_linko" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                          <Route path="/s/:subjectName" element={<PrivateRoute><SubjectPage /></PrivateRoute>} />
                          <Route path="/s/my/:subjectName" element={<PrivateRoute><SubjectPage /></PrivateRoute>} />
                          {/* Public App Pages */}
                          <Route path="/profile/:userId" element={<PublicProfile />} />
                          <Route path="/n/:noteId" element={<NotePage />} />
                          <Route path="/new-note" element={<PrivateRoute><NewNotePage /></PrivateRoute>} />
                          {/* Account Pages */}
                          <Route path="/my_friends/:username" element={<PrivateRoute><AccountPage /></PrivateRoute>} />
                          <Route path="/settings/:username/" element={<PrivateRoute><SettingPage /></PrivateRoute>} />
                          <Route path="/notifications/:username/" element={<PrivateRoute><NotificationPage /></PrivateRoute>} />
                          {/* Policies */}
                          <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
                          <Route path="/term_of_service" element={<TermOfService/>} />
                          <Route path="/cookie_policy" element={<CookiePolicy/>} />
                          <Route path="/knowledge_graph_explained" element={<KnowledgeGraphExplained/>} />
                        </Routes>
                        </div>
                        <div id="footer-container" >
                          <Footer />
                        </div>
                      </div>
                    </HomePageProvider>
                  </AppContextProvider>
                </GoogleOAuthProvider>
              </ToastProvider>
            </NavigationPromptProvider>
          </ModalProvider>
        </NavigationProvider>
    </Router>
  );
}

export default App;
