import React, {useContext} from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useToast } from '../../Components/ToastNotification';
import { useModal } from '../../Context/ModalContext';
import EditCustomTag from './EditCustomTag';
import { FiEdit3 } from 'react-icons/fi';


const SubjectDescription = ({ 
    publicField, 
    subjectName, 
    description, 
    setDescription,
    noteCount, 
    resourceCount, 
    editTag, 
    setEditTag, 
    newSubjectName, 
    setNewSubjectName, 
    subjectId, 
}) => {

    const { safeNavigate } = useContext(NavigationPromptContext);
    const { setPersistentMessage }= useToast();
    const { requestConfirm } = useModal();

    return (
        <div className='subject-rel'>
            {editTag ?
                <EditCustomTag 
                    subjectName={subjectName}
                    newSubjectName={newSubjectName} 
                    setNewSubjectName={setNewSubjectName} 
                    description={description}
                    setDescription={setDescription}
                    setEditTag={setEditTag} 
                    subjectId={subjectId} 
                    safeNavigate={safeNavigate} 
                    setPersistentMessage={setPersistentMessage} 
                    requestConfirm={requestConfirm}
                />
            :
            <>
                {!publicField ?
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', minHeight: '40px'}}>
                        <div className='subject-rel-title' style={{marginTop: '0px', marginBottom: '5px', padding: '0px', color: '#f27215', fontSize: '22px', fontWeight: '800'}}>{subjectName}</div>
                        {publicField === false && !editTag &&
                            <FiEdit3 onClick={() => setEditTag(true)} title='Edit tag' size={18} style={{cursor:'pointer', color:'#999999', marginTop:'8px'}} />
                        }
                    </div>
                    :
                    <div className='subject-rel-title' style={{marginTop: '0px', marginBottom: '5px', padding: '0px', fontSize: '22px', fontWeight: '800', minHeight: '40px'}}>{subjectName}</div>
                }
                <div className='subject-learn-count'>
                    { noteCount > 0 && <span>{noteCount} note{noteCount > 1 ? 's':''}</span>}
                    { resourceCount > 0 && <span>{resourceCount} resource{resourceCount > 1 ? 's':''}</span>}
                </div>
                {description && <div className='description'>{description}</div>}
            </>
            }
        </div>
    );
};

export default SubjectDescription;
