import React, { useRef, useEffect } from 'react';
import { PiNotePencilBold } from "react-icons/pi";
import ReactMarkdown from 'react-markdown';
import Loader from '../Loader';
import { subjectPage, profilePage } from '@linko/shared_utils';
import CustomLink from '../CustomLink';
import SlimResourceCard from '../ResourceCard/SlimResourceCard';

const SearchResults = ({
    isLoading,
    // users,
    resources,
    notes,
    subject,
    isFocused,
    searchTerm,
    setCloseAllowed,
}) => {
    const searchResultsRef = useRef(null);
    const sectionTitleRefs = useRef([]);
    sectionTitleRefs.current = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const truncateMarkdown = (markdown, maxLength) => {
        if (markdown.length <= maxLength) return markdown;
        let truncated = markdown.substr(0, maxLength);
        truncated = truncated.replace(/\[([^\]]+)?$/, '');
        truncated = truncated.replace(/\*([^\*]+)?$/, '');
        truncated = truncated.replace(/\_([^\_]+)?$/, '');
        truncated = truncated.replace(/\`([^\`]+)?$/, '');
        return truncated + '...';
    };

    const handleScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        const container = searchResultsRef.current;
        if (container) {
            container.scrollTop += e.deltaY;
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.getAttribute('id');
                    if (entry.isIntersecting) {
                        entry.target.classList.add('sticky');
                    } else {
                        entry.target.classList.remove('sticky');
                    }
                });
            },
            { threshold: 0.1 }
        );

        sectionTitleRefs.current.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            sectionTitleRefs.current.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    useEffect(() => {
        const container = searchResultsRef.current;
        if (container && isFocused) {
            container.addEventListener('wheel', handleScroll, { passive: false });
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleScroll);
            }
        };
    }, [isFocused]);

    if (!isFocused || searchTerm === '') {
        return null;
    }

    return (
        <div 
            className="full-search-results" 
            ref={searchResultsRef}
        >
            {isLoading && <Loader />}
            {/* User */}
            {/* {users && users.length > 0 && ( 
                <div ref={sectionTitleRefs.current[0]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>Users</h2>
                    <div className='full-search-results-subjects'>
                        {users.length > 0 ? (
                            users.map(user => (
                                <div className='search-results-user' key={user.id}>
                                    <CustomLink
                                        to={profilePage(user.id, user.first_name, user.last_name)}
                                        onClick={() => setCloseAllowed(false)}
                                    >
                                        {user.first_name} {user.last_name}
                                    </CustomLink>
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )} */}

            {/* Resource */}
            {resources && resources.length > 0 && (
                <div ref={sectionTitleRefs.current[1]} style={{ width: '100%' }}>
                    <h2 ref={sectionTitleRefs.current[2]} className='full-search-results-title'>My resources</h2>
                    <div className='full-search-results-subjects'>
                        {resources.length > 0 ? (
                            resources.map(resource => (
                                <SlimResourceCard
                                    key={resource.id}
                                    id={resource.id}
                                    resource={resource}
                                    learnHistory={null}
                                    onResourceClick={() => setCloseAllowed(false)}
                                />
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {/* Note */}
            {notes && notes.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>My notes</h2>
                    <div className='full-search-results-subjects'>
                        {notes.length > 0 ? (
                            notes.map(note => {
                                const truncatedNote = truncateMarkdown(note.note, 150);
                                return (
                                    <div className='search-results-resource' key={note.id}>
                                        <PiNotePencilBold size={18} color='#999999' style={{flexShrink:'0'}}/>
                                        <CustomLink
                                            to={`/n/${note.id}`}
                                            onClick={() => setCloseAllowed(false)}
                                        >
                                            <div className='search-results-note-content'>
                                                {note.title && <div style={{fontSize: '14px', fontWeight: 'bold', color: '#333'}}>{note.title}</div>}
                                                <ReactMarkdown style={{fontSize: '14px'}}>
                                                    {truncatedNote}
                                                </ReactMarkdown>
                                            </div>
                                        </CustomLink>
                                    </div>
                                );
                            })
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {/* Subject */}
            {subject?.myTag && subject.myTag.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>My Tags</h2>
                    <div className='full-search-results-subjects'>
                        {subject.myTag.length > 0 ? (
                            subject.myTag.map(subject => (
                                <div className="subject-tag cus-label" key={subject.id}>
                                    <CustomLink
                                        to={subjectPage(subject.name, true)}
                                        onClick={() => setCloseAllowed(false)}
                                    >
                                        {subject.name}
                                    </CustomLink>
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {subject?.studied && subject.studied.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>Fields Explored</h2>
                    <div className='full-search-results-subjects'>
                        {subject.studied.length > 0 ? (
                            subject.studied.map(subject => (
                                <div className="subject-tag studied" key={subject.id}>
                                    <CustomLink
                                        to={subjectPage(subject.name, false)}
                                        onClick={() => setCloseAllowed(false)}
                                >
                                    {subject.name}
                                    </CustomLink>
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {subject?.notStudied && subject.notStudied.length > 0 && (
                <div ref={sectionTitleRefs.current[3]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>All Fields</h2>
                    <div className='full-search-results-subjects'>
                        {subject.notStudied.length > 0 ? (
                            subject.notStudied.map(subject => (
                                <div className="subject-tag not-studied" key={subject.id}>
                                    <CustomLink
                                        to={subjectPage(subject.name, false)}
                                        onClick={() => setCloseAllowed(false)}
                                    >
                                        {subject.name}
                                    </CustomLink>
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchResults;
