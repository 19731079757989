import React, { useEffect, useState, useContext} from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../Context/AppContext';
import BackButton from '../../Components/BackButton';
import PublicProfileHeader from './PublicProfileHeader';
import TabLibrary from '../../Components/ContentTabs/TabLibrary';
import UserSubjects from './UserSubjects';
import UserFollows from './UserFollows';
import RecentlyAdded from '../HomePage/RecentlyAdded';

import { fetchUserData, fetchMyFeed } from '@linko/shared_utils'; 

const PublicProfile = () => {

  const [privateAccount, setPrivateAccount] = useState(true);
  const [privateMessage, setPrivateMessage] = useState('');
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userRecentResources, setUserRecentResources] = useState([]);
  const [userCommonResources, setUserCommonResources] = useState([]);

  const [library, setLibrary] = useState([]);
  const [libraryOffset, setLibraryOffset] = useState(0);
  const [isFetchingMoreLibrary, setIsFetchingMoreLibrary] = useState(false);
  const [hasMoreLibrary, setHasMoreLibrary] = useState(true);

  const limit = 10;

  const { userId } = useParams();
  const { userInfo } = useContext(UserContext);
  const isCurrentUser = userInfo?.id === userId;

  const fetchUserName = async () => {
    if (isCurrentUser) {
      setUserFirstName(userInfo.first_name);
      setUserLastName(userInfo.last_name);
    } else {
      const response = await fetchUserData(userId);
      setUserFirstName(response.data.first_name);
      setUserLastName(response.data.last_name);
      setUserRecentResources(response.data.recent_resources);
      setUserCommonResources(response.data.common_resources);
      setPrivateAccount(response.data.private_account);
      setPrivateMessage('This account is private. You cannot see their library.');
    }
  };

  const fetchLibrary = async () => {

    if (!hasMoreLibrary) return;

    try {
      if (isCurrentUser) {
        const response = await fetchMyFeed(limit, libraryOffset, null, null, null, setLibrary, setLibraryOffset, setHasMoreLibrary, setIsFetchingMoreLibrary);
      } else if (!privateAccount) {
        const response = await fetchMyFeed(limit, libraryOffset, null, null, userId, setLibrary, setLibraryOffset, setHasMoreLibrary, setIsFetchingMoreLibrary);
      }
    } catch (error) {
      console.error('Error fetching library:', error);
    } finally {
      setIsFetchingMoreLibrary(false);
    }
  };

  const handleScrollEvent = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setIsFetchingMoreLibrary(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    setLibrary([]);
    setUserFirstName(null);
    setUserLastName(null);
    setPrivateAccount(false);
    setLibraryOffset(0);
    setHasMoreLibrary(true);
    setIsFetchingMoreLibrary(true);
    fetchUserName();
  }, [userId, isCurrentUser]);

  useEffect(() => {
    if (isFetchingMoreLibrary) {
      fetchLibrary();
    }
  }, [isFetchingMoreLibrary, userId, isCurrentUser]);

    return (
      <div className='page-container public-profile'>
        <div className='page-left'>
          <BackButton />
          <PublicProfileHeader 
            userId={userId}
            userFirstName={userFirstName}
            userLastName={userLastName}
            privateAccount={privateAccount}
          />
          {(privateAccount && !isCurrentUser) ? (
            <div className='note-card-wrapper' style={{marginTop:'30px'}}>
              <div className='note-card' style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', rowGap:'10px', color:'#666666'}}>
                {privateMessage}
              </div>
            </div>
          ) : (
            <div className='the-tabs'>
              <div className="tab-content">
                <div id='tab-library'>
                <TabLibrary
                  library={library}
                  isFetchingMoreLibrary={isFetchingMoreLibrary}
                  hasMoreLibrary={hasMoreLibrary}
                />
                </div>
              </div>
            </div>
          )}
        </div>
        {(isCurrentUser || !privateAccount) ? (
          <div className='home-page-right'>
            <UserSubjects 
              userId={userId}
              isCurrentUser={isCurrentUser}
            />
            <RecentlyAdded 
              userRecentResources={userRecentResources}
              userCommonResources={userCommonResources}
              userName={userFirstName}
            />
            <UserFollows 
              userId={userId}
              userName={userFirstName}
              isCurrentUser={isCurrentUser}
            />
          </div>
        ) : null}
      </div>
    );
};

export default PublicProfile;
