export const convertResourceType = (type) => {
    switch (type) {
        case 'bo':
            return 'book';
        case 'ar':
            return 'article';
        case 'po':
            return 'podcast';
        case 'vi':
            return 'video';
        case 'oc':
            return 'online course';
        case 'or':
            return 'link';
        default:
            return 'link';
    }
};

export const cleanAuthorName = (authorString) => {
    if (!authorString) return ''; 
    return authorString.replace(/\([^)]*\)|\[[^\]]*\]/g, '').trim();
};

export const subjectPage = (subjectName, myTag) => {
    if (myTag === true) {
        return `/s/my/${encodeURIComponent(subjectName)}`;
    } else {
        return `/s/${encodeURIComponent(subjectName)}`;
    }
};

export const getBookPreviewUrl = async (bookId) => {
    try {
      const response = await fetch(`https://www.googleapis.com/books/v1/volumes/${bookId}`);
      const data = await response.json();
      
      if (data.accessInfo && data.accessInfo.webReaderLink) {
        return data.accessInfo.webReaderLink;
      } else {
        throw new Error('No preview available for this book');
      }
    } catch (error) {
      console.error('Error fetching book preview URL:', error);
      throw error;
    }
};

export const profilePage = (id) => {
    return `/profile/${id}`;
};