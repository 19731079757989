import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

const Platform = {
  OS: typeof navigator !== 'undefined' && navigator.product === 'ReactNative' ? 'native' : 'web'
};

/**
 * Upload a file to S3 using pre-signed URLs.
 * 
 * @param {File} file - The file to upload
 * @param {Function} onProgress - Callback for upload progress (0-100)
 * @returns {Promise<string>} The public URL of the uploaded file
 * @throws {Error} If upload fails
 */
export const uploadFileToS3 = async (file, onProgress = () => {}) => {
  try {
    // Step 1: Get authentication token
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }

    // For React Native, we need to modify the file object
    const modifiedFile = Platform.OS !== 'web' ? {
      uri: file.uri,
      name: file.name || `image_${Date.now()}.${file.type.split('/')[1]}`,
      type: file.type
    } : file;


    // Step 2: Get pre-signed URL from backend
    const { data } = await axios.post('/api/files/get_upload_url/', {
      filename: modifiedFile.name,
      fileType: modifiedFile.type
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    if (!data.uploadUrl || !data.fields || !data.publicUrl) {
      throw new Error('Invalid response from server');
    }

    // Step 3: Prepare form data for S3 upload
    const formData = new FormData();
    
    // Add all fields from the pre-signed URL response
    Object.keys(data.fields).forEach(key => {
      formData.append(key, data.fields[key]);
    });
    
    // Add the file last
    if (Platform.OS !== 'web') {
      formData.append('file', {
        uri: modifiedFile.uri,
        type: modifiedFile.type,
        name: data.fields.key.split('/').pop(),
      });
    } else {
      formData.append('file', modifiedFile);
    }


    // Step 4: Upload to S3 using vanilla fetch to avoid axios interceptors
    const response = await fetch(data.uploadUrl, {
      method: 'POST',
      body: formData,
      headers: Platform.OS !== 'web' ? {
        'Content-Type': 'multipart/form-data',
      } : undefined, // Let browser handle Content-Type for web
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Upload failed with status:', response.status);
      console.error('Error response:', errorText);
      throw new Error(errorText || 'Upload failed');
    }

    return data.publicUrl;

  } catch (error) {
    console.error('Upload error full details:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      throw new Error(error.response.data.error || error.response.data || 'Upload failed');
    } else if (error.request) {
      console.error('Error request:', error.request);
      throw new Error('No response from server');
    } else {
      console.error('Error message:', error.message);
      throw new Error(error.message || 'Upload failed');
    }
  }
};

/**
 * Delete a file from S3.
 * 
 * @param {string} fileKey - The S3 key of the file to delete
 * @returns {Promise<boolean>} True if deletion was successful
 * @throws {Error} If deletion fails
 */
export const deleteFileFromS3 = async (fileKey) => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }

    await axios.delete('/api/files/delete_file/', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
      params: {
        key: fileKey
      }
    });

    return true;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error || 'Delete failed');
    } else if (error.request) {
      throw new Error('No response from server');
    } else {
      throw new Error(error.message || 'Delete failed');
    }
  }
}; 