import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { useModal } from "../../Context/ModalContext";
// Context
import { AddNoteCardContext, UserContext } from '../../Context/AppContext';
import { HomePageContext } from '../../Context/HomePageContext';
// Components
import EditNoteCard from "./EditNoteCard";
// Packages
import { postNote } from "@linko/shared_utils";
// Icons
import { PiNotePencilBold } from "react-icons/pi";

const AddNoteButton = ({
    resource,
    subjectId,
    noteHint,
    showTextArea,
    setShowTextArea,
    setLibrary,
}) => {

    const { userInfo, fetchUserInfo, fetchUserSubjectData } = useContext(UserContext);
    const [ title, setTitle ] = useState('');
    const [ note, setNote ] = useState('');
    const [ isPrivate, setIsPrivate ] = useState(userInfo?.private_note);
    const [ noteResource, setNoteResource ] = useState(null);
    const [ editorContent, setEditorContent ] = useState('');
    const { newNoteContent, setNewNoteContent } = useContext(AddNoteCardContext);
    const { registerEditorChanges } = useContext(NavigationPromptContext);
    const { requestConfirm } = useModal();
    const [ isLoading, setIsLoading ] = useState(false);
    const location = useLocation();
    const { addNoteToLibrary, setHomePageState } = useContext(HomePageContext);

    useEffect(() => {
        if (resource && resource.resource) {
            setNoteResource(resource.resource);
        }
    }, [resource]);

    useEffect(() => {
        if (newNoteContent !== '') {
            if (!showTextArea) {
                setShowTextArea(true);
            }
            
            const blockquoteContent = newNoteContent.split('\n').map(line => `> ${line}`).join('\n');
            
            setTimeout(() => {
                setNote(prevContent => {
                    const newContent = prevContent ? `${prevContent}\n\n${blockquoteContent}` : blockquoteContent;
                    setEditorContent(newContent);
                    return newContent;
                });
            }, 500);
            
            setNewNoteContent('');
        }
    }, [newNoteContent, showTextArea]);

    useEffect(() => {
        if (showTextArea && userInfo) {
            setIsPrivate(userInfo?.private_note);
        }
    }, [showTextArea, userInfo]);

    const handleAddButtonClick = () => {    
        setShowTextArea(true);
        registerEditorChanges('0', true);
    };

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!note.trim()) {
            window.alert('Note cannot be empty');
            return;
        }
        
        setIsLoading(true);
        try {
            const newNote = {
                title: title,
                note: note,
                private: userInfo?.private_account ? true : isPrivate,
                resource_id: noteResource ? noteResource.id : null,
                knowledge_id: subjectId ? subjectId : null,
            };
            
            const response = await postNote(newNote);
            
            if (response.status === 201) {
                const noteData = response.data.note;            
                
                // Update other states
                fetchUserInfo();
                fetchUserSubjectData();
                addNoteToLibrary(noteData);
                
                // Reset form state
                registerEditorChanges('0', false);
                setTitle('');
                setNote('');
                setEditorContent('');
                setIsPrivate(userInfo?.private_note);
                setShowTextArea(false);
                
                if (!location.pathname.includes('/r/')) {
                    setNoteResource(null);
                }
                if (!location.pathname.includes('/my_linko')) {
                    setLibrary(prevLibrary => [noteData, ...prevLibrary]);
                } else {
                    setHomePageState(prevState => ({
                        ...prevState,
                        activeTab: 'library',
                    }));
                }
            }
        } catch (error) {
            console.error('Error adding note:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // handleCancel
    const handleCancel = (event) => {
        event.preventDefault();

        // Check if there are unsaved changes
        const hasChanges = title.trim() !== '' || note.trim() !== '';

        if (hasChanges) {
            requestConfirm(
                'You have unsaved changes. Are you sure you want to discard them?',
                () => {
                    // Reset local states
                    registerEditorChanges('0', false);
                    setShowTextArea(false);
                    setTitle('');
                    setNote('');
                    setEditorContent('');
                    setIsPrivate(userInfo?.private_note);
                    if (!location.pathname.includes('/r/')) {
                        setNoteResource(null);
                    }
                },
                () => {
                    // Do nothing, keep editing
                },
                'Discard'
            );
        } else {
            registerEditorChanges('0', false);
            setShowTextArea(false);
            setTitle('');
            setNote('');
            setEditorContent('');
            setIsPrivate(userInfo?.private_note);
            if (!location.pathname.includes('/r/')) {
                setNoteResource(null);
            }
        }
    };

    return (
        <div className="add-note-card-button">
            {showTextArea ? (
                <EditNoteCard
                    isLoading={isLoading}
                    showTextArea={showTextArea}
                
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    updateSingleNote={null}

                    title={title}
                    setTitle={setTitle}
                    note={note}
                    setNote={setNote}
                    isPrivate={isPrivate}
                    setIsPrivate={setIsPrivate}
                    noteResource={noteResource}
                    setNoteResource={setNoteResource}
                    editorContent={editorContent}
                    setEditorContent={setEditorContent}
                />
            ) : (
                <button onClick={handleAddButtonClick} id="add-note-button" className='joyride-add-note'>
                    <div className="inside-add-note-button">
                        <PiNotePencilBold className="add-icon" />
                        <p className="show-on-hover" style={{position:'absolute', bottom:'10px', color:'#2e2e2e86', fontSize:'12px'}}>{noteHint}</p>
                    </div>
                </button>
            )}
        </div>
    );
};

export default AddNoteButton;
