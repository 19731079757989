import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { handleLogout, putUserData, useWindowSize } from '@linko/shared_utils';
import { AuthContext, UserContext } from '../../Context/AppContext';
import { FaRegBell, FaRegCommentDots } from "react-icons/fa6";
import { TbSettings, TbUsers, TbLayoutSidebar } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { IoLibraryOutline } from "react-icons/io5";

const NameDropdown = ({userInfo, notificationCount, showSidebar, setShowSidebar}) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();
    const { setIsAuthenticated } = useContext(AuthContext);
    const { setUserInfo, fetchUserInfo } = useContext(UserContext);
    const { width } = useWindowSize();

    const handleLogoutPress = async () => {
        try {
            await handleLogout();
            setIsAuthenticated(false);
            setUserInfo(null);
            safeNavigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [location]);

    const toggleSidebar = async () => {
        const newShowSidebar = !showSidebar;
        setShowSidebar(newShowSidebar);
        try {
            await putUserData({ hide_sidebar: !newShowSidebar });
            await fetchUserInfo();
        } catch (error) {
            console.error('Failed to update sidebar preference:', error);
        }
    };

    useEffect(() => {
        // Only add event listener if width is >= 1089
        if (width < 1089) return;

        const handleKeyPress = async (event) => {
            // Check if we're on the home page
            if (!location.pathname.includes('my_linko')) return;
            
            // Check for Cmd+B (macOS) or Ctrl+B (Windows)
            if ((event.metaKey || event.ctrlKey) && event.key === 'b') {
                event.preventDefault(); // Prevent default browser behavior
                await toggleSidebar();
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [location.pathname, showSidebar, width]); // Added width as dependency

    return (
        <div 
        className="username-dropdown" 
        onMouseEnter={() => setIsDropdownOpen(true)}
        onMouseLeave={() => setIsDropdownOpen(false)} 
        >
            <button className="linko-button linko-button--primary navbar-user-name" >
                {userInfo && <span>{userInfo.first_name}</span>}
            </button>
            {notificationCount > 0 && <div className="notification-badge"></div>}
            {isDropdownOpen && (
                <div className="name-dropdown-menu">
                    {!location.pathname.includes('my_library') && (
                        <div className='dropdown-item setting' onClick={() => safeNavigate('/my_library')}>
                            <IoLibraryOutline size={16} color='#999'/>
                            <p>Library view</p>
                        </div>
                    )}
                    {!userInfo?.private_account && (
                        <div className='dropdown-item setting' onClick={() => safeNavigate(`/notifications/${userInfo.first_name}${userInfo.last_name}`)}>
                            <FaRegBell size={16} color='#999'/>
                            <p>Notifications</p>
                            {notificationCount > 0 && <div className="notification-count">{notificationCount}</div>}
                        </div>
                    )}
                    {!userInfo?.private_account && (
                        <div className='dropdown-item setting' onClick={() => safeNavigate(`/my_friends/${userInfo.first_name}${userInfo.last_name}`)}>
                            <TbUsers size={16} color='#999'/>
                            <p>Friends</p>
                        </div>
                    )}
                    <div className='dropdown-item setting' onClick={() => safeNavigate(`/settings/${userInfo.first_name}${userInfo.last_name}`)}>
                        <TbSettings size={16} color='#999'/>
                        <p>Settings</p>
                    </div>
                    {location.pathname.includes('my_linko') && width > 1089 && (
                        <div className='dropdown-item setting sidebar-toggle' onClick={toggleSidebar}>
                            <TbLayoutSidebar size={16} color='#999'/>
                            <p>{showSidebar ? 'Hide Sidebar' : 'Show sidebar'} <span>Cmd+B</span></p>
                        </div>
                    )}
                    <div className='rx-divider-vertical'></div>
                    <a className='dropdown-item setting' href='https://linko.featurebase.app/' target='_blank'>
                        <FaRegCommentDots size={16} color='#999'/>
                        <p>Feedback</p>
                    </a>
                    <div className="logout" onClick={handleLogoutPress}>
                        <FiLogOut size={16} />
                        <p>Logout </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NameDropdown;