import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { subjectPage } from '@linko/shared_utils';
import CustomLink from '../../Components/CustomLink';
import { TbTriangleFilled, TbHierarchy } from "react-icons/tb";


const SubjectRel = ({ broaderField = [], siblingFields = [], childFields = [], subjectId }) => {
    const [showAllSibling, setShowAllSibling] = useState(false);
    const [showAllChild, setShowAllChild] = useState(false);

    // Move sorting to useMemo to avoid re-sorting on every render
    const sortedChildFields = React.useMemo(() => 
        [...childFields].sort((a, b) => b.learn_count - a.learn_count),
        [childFields]
    );

    const sortedSiblingFields = React.useMemo(() => {
        const currentSubject = siblingFields.find(field => field.id === subjectId);
        const otherSubjects = siblingFields
            .filter(field => field.id !== subjectId)
            .sort((a, b) => b.learn_count - a.learn_count);
        return currentSubject ? [currentSubject, ...otherSubjects] : otherSubjects;
    }, [siblingFields, subjectId]);

    const renderSubjectTag = (field, index, isCurrentSubject = false) => (
        <div 
            key={index}
            className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'} ${isCurrentSubject ? 'current-subject' : ''}`}
        >
            <CustomLink to={subjectPage(field.name, false)}>
                {field.name}
                {field.learn_count > 0 && ` · ${field.learn_count}`}
            </CustomLink>
        </div>
    );

    const renderShowButton = (isShowing, onClick, text) => (
        <button className='show-button' onClick={onClick}>
            {text} <FaAngleUp style={{marginLeft:'5px', marginTop:'2px'}}/>
        </button>
    );

    return (
        <div className='subject-rel'>
            {/* <div className='subject-rel-title'>Knowledge Tree</div> */}
            
            {broaderField.length > 0 && 
                <div className='subject-rel-list knowledge-tree-list'>
                    {broaderField.map((field, index) => renderSubjectTag(field, index))}
                </div>
            }

            {broaderField.length > 0 &&
                <div className='knowledge-tree-triangle'>
                    <TbHierarchy color='#257953' size={16}/>
                </div>
            }

            <div 
                className='subject-rel-list knowledge-tree-list'
                style={{paddingBottom:'8px'}}
            >
                {sortedSiblingFields
                    .slice(0, 1)
                    .map((field, index) => renderSubjectTag(field, index, field.id === subjectId))}
            </div>

            {sortedSiblingFields.length > 0 && showAllSibling &&
                <div 
                    className='subject-rel-list knowledge-tree-list'
                    style={{paddingTop:'0px'}}
                >
                    {sortedSiblingFields
                        .slice(1)
                        .map((field, index) => renderSubjectTag(field, index, field.id === subjectId))}
                </div>
            }

            <div className='show-sibling-button'>
                {sortedSiblingFields.length > 0 && (
                    showAllSibling ? 
                        renderShowButton(true, () => setShowAllSibling(false), 'Hide') :
                        <button 
                            className='show-button' 
                            style={{height:'fit-content'}}
                            onClick={() => setShowAllSibling(true)}
                        >
                            Show siblings <FaAngleDown style={{marginLeft:'5px', marginTop:'2px'}}/>
                        </button>
                )}
            </div>

            {sortedChildFields.length > 0 &&
                <div className='knowledge-tree-triangle'>
                    <TbHierarchy color='#257953' size={16}/>
                </div>
            }

            {sortedChildFields.length > 0 &&
                <div className='subject-rel-list knowledge-tree-list'>
                    {sortedChildFields
                        .slice(0, showAllChild ? sortedChildFields.length : 5)
                        .map((field, index) => renderSubjectTag(field, index))}
                    
                    {sortedChildFields.length > 5 && (
                        showAllChild ? 
                            renderShowButton(true, () => setShowAllChild(false), 'Show less') :
                            <button className='show-button' onClick={() => setShowAllChild(true)}>
                                Show more <FaAngleDown style={{marginLeft:'5px', marginTop:'2px'}}/>
                            </button>
                    )}
                </div>
            }
        </div>
    );
};

export default SubjectRel;
