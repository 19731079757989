import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

const AUTH_ERRORS = {
    INVALID_TOKEN: 'Invalid authentication token',
    NETWORK_ERROR: 'Network error occurred',
    BETA_CODE_REQUIRED: 'Beta code required for new users',
    INVALID_BETA_CODE: 'Invalid beta code provided',
};

const handleAuthSuccess = async (responseData, setIsError, setLoginStatus) => {
    await storageUtils.setItem('access_token', responseData.access);
    await storageUtils.setItem('refresh_token', responseData.refresh);
    axios.defaults.headers.common["Authorization"] = `Bearer ${responseData.access}`;
    setIsError(false);
    setLoginStatus('Login successful');
};

const handleAuthError = (error, setIsError, setLoginStatus) => {
    setIsError(true);
    setLoginStatus(error.response?.data?.message || AUTH_ERRORS.NETWORK_ERROR);
    throw error;
};

export const handleGoogleLogin = async (credential, platform, setIsError, setLoginStatus, onBetaCodeRequired) => {
    try {
        const token = platform === 'web' ? credential.credential : credential.token;
        
        const response = await axios.post('/api/auth/google_login/', {
            token,
            platform,
            beta_code: credential.betaCode
        });

        if (response.data.requires_beta_code) {
            if (onBetaCodeRequired) {
                onBetaCodeRequired(credential);
            }
            return null;
        }

        if (response.data.access && response.data.refresh) {
            await handleAuthSuccess(response.data, setIsError, setLoginStatus);
            return response.data;
        }

        throw new Error(AUTH_ERRORS.INVALID_TOKEN);
    } catch (error) {
        handleAuthError(error, setIsError, setLoginStatus);
    }
}; 