// auth
export { handleLogin } from './shared_utils/auth/handleLogin';
export { handleLogout } from './shared_utils/auth/handleLogout';
export { register, activateAccount } from './shared_utils/auth/register';
export { forgetPassword, resetPassword } from './shared_utils/auth/setPassword';
export { handleGoogleLogin } from './shared_utils/auth/handleGoogleLogin';

// user
export { fetchMyData, fetchUserData, fetchUserHeatmap } from './shared_utils/user/fetchUserData';
export { putUserData } from './shared_utils/user/putUserData';
export { fetchUserFollow, acceptFollowRequest, sendFollowRequest, handleFollowAction } from './shared_utils/user/handleUserFollow';

// notifications
export { getNotif, getNotifHistory, getNotifCount, connectToNotificationSSE } from './shared_utils/notifications/getNotif';

// search
export { searchBooks, searchAuthorBooks } from './shared_utils/search/searchBooks';
export { searchKnowledge } from './shared_utils/search/searchKnowledge';
export { searchMyResources } from './shared_utils/search/searchMyResources';
export { searchMyNotes } from './shared_utils/search/searchMyNotes';
export { searchUsers } from './shared_utils/search/searchUsers';
export { searchAll } from './shared_utils/search/searchAll';

// notes 
export { fetchNotes, fetchRevisitData } from './shared_utils/note/fetchNotes';
export { getSingleNote } from './shared_utils/note/getSingleNote';
export { postNote } from './shared_utils/note/postNote';
export { putNote } from './shared_utils/note/putNote';
export { deleteNote } from './shared_utils/note/deleteNote';
export { uploadFileToS3, deleteFileFromS3 } from './shared_utils/note/fileService';

// resource
export { fetchResources, fetchBestUnlearned } from './shared_utils/resource/fetchResources';
export { fetchSingleResource } from './shared_utils/resource/fetchSingleResource';
export { postResourceToMine, postResourceAndLearn, postNewResource } from './shared_utils/resource/postResource';
export { putMyResource, putResource } from './shared_utils/resource/putResource';
export { deleteResource } from './shared_utils/resource/deleteResource';
// feed
export { fetchMyFeed } from './shared_utils/feed/fetchMyFeed';
export { fetchFollowingFeed } from './shared_utils/feed/fetchFollowingFeed';
export { putLikeToFeed } from './shared_utils/feed/putLikeToFeed';

// subject
export { fetchUserSubject, fetchRelatedSubjects } from './shared_utils/subject/fetchUserSubject';
export { fetchSubjectId } from './shared_utils/subject/fetchSubjectId';
export { putMySubject, deleteMySubject } from './shared_utils/subject/putMySubject';
export { fetchSuggestedSubjects } from './shared_utils/subject/fetchSuggested';

// hooks
export { useWindowSize } from './shared_utils/hooks/useWindowSize';
export { useScrollToEnd } from './shared_utils/hooks/useScrollToEnd';

// utils
export { convertResourceType, cleanAuthorName, subjectPage, getBookPreviewUrl, profilePage } from './shared_utils/utils/utils';
export { fetchSummary } from './shared_utils/utils/Summary';
export { DateFormatter } from './shared_utils/utils/DateFormatter';
export const BETA_CODE = 'LinkoEarlyBird2025';