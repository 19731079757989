export const checkFollowStatus = (userId, userFirstName, followers, followings, requesting) => {
    let friendStatus = null;
    let followStatus = 'Request follow';
    let buttonDisabled = false;
    let buttonAction = 'follow';
    let confirmMessage = '';

    if (userFirstName !== null) {
        if (followers.some(follower => follower.id === userId) && !followings.some(following => following.id === userId) && !requesting.some(request => request.id === userId)) {
            friendStatus = `${userFirstName} is following you`;
            followStatus = 'Request follow';
            buttonDisabled = false;
            buttonAction = 'follow';
        } else if (followings.some(following => following.id === userId) && !followers.some(follower => follower.id === userId)) {
            friendStatus = `You are following ${userFirstName}`;
            followStatus = 'Following';
            buttonDisabled = false;
            buttonAction = 'unfollow';
            confirmMessage = `Are you sure you want to unfollow ${userFirstName}?`;
        } else if (followers.some(follower => follower.id === userId) && followings.some(following => following.id === userId)) {
            friendStatus = `You and ${userFirstName} are following each other`;
            followStatus = 'Friends';
            buttonDisabled = false;
            buttonAction = 'unfollow';
            confirmMessage = `Are you sure you want to unfollow ${userFirstName}?`;
        } else if (requesting.some(request => request.id === userId)) {
            friendStatus = `Follow request pending`;
            followStatus = 'Pending';
            buttonDisabled = true;
            buttonAction = 'none';
        } else {
            friendStatus = null;
            followStatus = 'Request follow';
            buttonDisabled = false;
            buttonAction = 'follow';
        }
    }

    return {
        friendStatus,
        followStatus,
        buttonDisabled,
        buttonAction,
        confirmMessage
    };
};
