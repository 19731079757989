import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FocusMode from '../../Components/NoteCard/FocusMode';
// Context
import { UserContext, AddNoteCardContext } from '../../Context/AppContext';
import { HomePageContext } from '../../Context/HomePageContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useModal } from '../../Context/ModalContext';
// API
import { postNote } from "@linko/shared_utils";

const NewNotePage = () => {
    const navigate = useNavigate();
    const { userInfo, fetchUserInfo, fetchUserSubjectData } = useContext(UserContext);
    const { addNoteToLibrary, setHomePageState } = useContext(HomePageContext);
    const { registerEditorChanges } = useContext(NavigationPromptContext);
    const { requestConfirm } = useModal();
    const { newNoteContent, setNewNoteContent } = useContext(AddNoteCardContext);

    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [noteResource, setNoteResource] = useState(null);
    const [isPrivate, setIsPrivate] = useState(userInfo?.private_note);
    const [editorContent, setEditorContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Set initial private state based on user preferences
    useEffect(() => {
        if (userInfo) {
            setIsPrivate(userInfo?.private_note);
        }
    }, [userInfo]);

    // Handle new note content if provided through context
    useEffect(() => {
        if (newNoteContent !== '') {
            const blockquoteContent = newNoteContent.split('\n').map(line => `> ${line}`).join('\n');
            setNote(prevContent => {
                const newContent = prevContent ? `${prevContent}\n\n${blockquoteContent}` : blockquoteContent;
                setEditorContent(newContent);
                return newContent;
            });
            setNewNoteContent('');
        }
    }, [newNoteContent]);

    const handleSubmit = async () => {
        if (!note.trim()) {
            window.alert('Note cannot be empty');
            return;
        }
        
        setIsLoading(true);
        try {
            const newNote = {
                title: title,
                note: note,
                private: userInfo?.private_account ? true : isPrivate,
                resource_id: noteResource ? noteResource.id : null,
                knowledge_id: null, 
            };
            
            const response = await postNote(newNote);
            
            if (response.status === 201) {
                const noteData = response.data.note;            
                
                // Update other states
                fetchUserInfo();
                fetchUserSubjectData();
                addNoteToLibrary(noteData);
                
                // Reset form state
                registerEditorChanges('new-note', false);
                
                // Update homepage state and navigate
                setHomePageState(prevState => ({
                    ...prevState,
                    activeTab: 'library',
                }));
                navigate('/my_linko');
            }
        } catch (error) {
            console.error('Error adding note:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = (event) => {
        event?.preventDefault();

        // Check if there are unsaved changes
        const hasChanges = title.trim() !== '' || note.trim() !== '';

        if (hasChanges) {
            requestConfirm(
                'You have unsaved changes. Are you sure you want to discard them?',
                () => {
                    // Reset local states and navigate away
                    registerEditorChanges('new-note', false);
                    navigate('/my_linko');
                },
                () => {
                    // Do nothing, keep editing
                },
                'Discard'
            );
        } else {
            registerEditorChanges('new-note', false);
            navigate('/my_linko');
        }
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        registerEditorChanges('new-note', true);
    };

    const handleEditorChange = useCallback((markdown) => {
        setNote(markdown);
        setEditorContent(markdown);
        registerEditorChanges('new-note', true);
    }, [registerEditorChanges]);

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleTitlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text/plain');
        const newTitle = pastedText.replace(/\n/g, ' ');
        
        const cursorPosition = e.target.selectionStart;
        const oldTitle = e.target.value;
        
        const updatedTitle = oldTitle.slice(0, cursorPosition) + newTitle + oldTitle.slice(cursorPosition);
        setTitle(updatedTitle);
        registerEditorChanges('new-note', true);
    };

    // Register editor changes when component mounts
    useEffect(() => {
        registerEditorChanges('new-note', false);
        return () => {
            registerEditorChanges('new-note', false);
        };
    }, [registerEditorChanges]);

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <FocusMode 
                handleSubmit={handleSubmit}
                title={title}
                handleTitleChange={handleTitleChange}
                handleEnterKey={handleEnterKey}
                handleTitlePaste={handleTitlePaste}
                setIsFullScreen={() => {}}
                isPrivate={isPrivate}
                handlePrivateChange={() => setIsPrivate(!isPrivate)}
                handleCancel={handleCancel}
                isLoading={isLoading}
                note={note}
                handleEditorChange={handleEditorChange}
                editorContent={editorContent}
                canExitFocusMode={false}
                noteResource={noteResource}
                setNoteResource={setNoteResource}
            />
        </div>
    );
};

export default NewNotePage; 