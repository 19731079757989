import React, { useRef, useState } from 'react';
import { BiImageAdd } from "react-icons/bi";
import Tippy from '@tippyjs/react';
import { uploadFileToS3 } from '@linko/shared_utils';

const FileUploader = ({ onImageUpload, isTouchDevice }) => {
  const imageInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageClick = (e) => {
    e.preventDefault();
    if (!isUploading) {
      imageInputRef.current?.click();
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Check if file is an image
    if (!file.type.startsWith('image/')) {
      alert('Please select an image file (JPEG, PNG, or GIF)');
      return;
    }

    // Check if it's one of the allowed image types
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      alert('Only JPEG, PNG, and GIF images are allowed');
      return;
    }

    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      alert('Image size should be less than 5MB');
      return;
    }

    try {
      setIsUploading(true);
      setUploadProgress(0);
      
      // Upload to S3 and get the URL
      const imageUrl = await uploadFileToS3(file, (progress) => {
        setUploadProgress(progress);
      });
      
      // Pass the URL to the parent component
      onImageUpload(imageUrl);
    } catch (error) {
      console.error('Error uploading image:', error);
      alert(error.message || 'Error uploading image. Please try again.');
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
      // Clear the input
      e.target.value = '';
    }
  };

  const getUploadTooltip = () => {
    if (isUploading) {
      return `Uploading... ${uploadProgress}%`;
    }
    return 'Upload Image';
  };

  return (
        <Tippy
            content={getUploadTooltip()}
            placement="top"
            arrow={true}
            theme='light-border'
            hideOnClick={true}
            delay={[0, 0]}
            touch={false}
            disabled={isTouchDevice}
        >
            <span 
                onMouseDown={handleImageClick}
                className="RichEditor-styleButton RichEditor-styleButton--image"
                style={{ 
                    color: isUploading ? '#267953' : '#999999',
                    cursor: isUploading ? 'not-allowed' : 'pointer',
                    position: 'relative',
                    marginTop: '3px'
                }}
            >
                <input
                    type="file"
                    ref={imageInputRef}
                    onChange={handleImageChange}
                    accept="image/jpeg,image/png,image/gif"
                    style={{ display: 'none' }}
                    disabled={isUploading}
                />
                <BiImageAdd size={16}/>
                {isUploading && (
                    <div 
                        className="upload-progress" 
                        style={{
                            position: 'absolute',
                            bottom: 5,
                            left: -1,
                            width: `${uploadProgress}%`,
                            height: '2px',
                            backgroundColor: '#267953',
                            transition: 'width 0.3s ease'
                        }}
                    />
                )}
            </span>
        </Tippy>
  );
};

export default FileUploader; 