import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

const PodcastPlayer = ({ url, setOmitPreview, description }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const getSpotifyEmbedUrl = (url) => {
        // Extract episode ID from Spotify URL
        const match = url.match(/episode\/([a-zA-Z0-9]+)/);
        if (match && match[1]) {
            return {
                type: 'spotify',
                embedUrl: `https://open.spotify.com/embed/episode/${match[1]}`
            };
        }
        return null;
    };

    const getApplePodcastEmbedUrl = (url) => {
        // Extract episode ID from Apple Podcast URL
        // Handles formats like:
        // https://podcasts.apple.com/us/podcast/episode-title/id1234567890?i=1000123456789
        // https://podcasts.apple.com/podcast/episode-title/id1234567890?i=1000123456789
        const match = url.match(/podcasts\.apple\.com\/(?:[^/]+\/)?podcast\/(?:[^/]+\/)?id(\d+)(?:\?i=(\d+))?/);
        if (match) {
            const podcastId = match[1];
            const episodeId = match[2];
            if (episodeId) {
                return {
                    type: 'apple',
                    embedUrl: `https://embed.podcasts.apple.com/us/podcast/id${podcastId}?i=${episodeId}&amp;theme=light`
                };
            } else {
                return {
                    type: 'apple',
                    embedUrl: `https://embed.podcasts.apple.com/us/podcast/id${podcastId}?theme=light`
                };
            }
        }
        return null;
    };

    const getEmbedInfo = (url) => {
        return getSpotifyEmbedUrl(url) || getApplePodcastEmbedUrl(url) || { type: null, embedUrl: url };
    };

    const embedInfo = getEmbedInfo(url);

    const formatDescription = (text) => {
        if (!text) return null;

        // Split into sections
        const sections = text.split(/\n\n+/);
        let sponsors = [];
        let outline = [];
        let mainDescription = [];

        let currentSection = null;
        sections.forEach(section => {
            if (section.trim().startsWith('SPONSORS:')) {
                currentSection = 'sponsors';
                sponsors.push(section);
            } else if (section.trim().startsWith('OUTLINE:')) {
                currentSection = 'outline';
                const lines = section.split('\n');
                // Skip the "OUTLINE:" header
                outline.push(...lines.slice(1));
            } else if (currentSection === 'outline' && section.includes(') - ')) {
                // Continue adding to outline if it matches timestamp pattern
                const lines = section.split('\n');
                outline.push(...lines);
            } else {
                currentSection = 'main';
                mainDescription.push(section);
            }
        });

        // Format links in sponsors section
        const formatLinks = (text) => {
            const urlRegex = /https?:\/\/[^\s<>"]+/g;
            return text.replace(urlRegex, url => 
                `<a href="${url}" target="_blank" rel="noopener noreferrer" class="external-link">${url}</a>`
            );
        };

        // Format timestamps in outline section
        const formatTimestamps = (text) => {
            const timestampRegex = /\((\d{1,2}):(\d{2}):?(\d{2})?\)\s*-\s*([^\n]+)/g;
            return text.replace(timestampRegex, (match, m1, m2, m3, title) => {
                const timestamp = m3 ? `${m1}:${m2}:${m3}` : `${m1}:${m2}`;
                return `<div class="outline-item"><span class="timestamp">${timestamp}</span>${title}</div>`;
            });
        };

        const visibleDescription = showFullDescription 
            ? mainDescription.join('\n\n')
            : mainDescription.slice(0, 1).join('\n\n').slice(0, 300) + 
              (mainDescription.slice(0, 1).join('\n\n').length > 300 ? '...' : '');

        return (
            <div>
                {sponsors.length > 0 && (
                    <div className="podcast-sponsors">
                        <h3>Sponsors</h3>
                        <div dangerouslySetInnerHTML={{ 
                            __html: DOMPurify.sanitize(formatLinks(sponsors.join('\n\n'))) 
                        }} />
                    </div>
                )}
                {outline.length > 0 && (
                    <div className="podcast-outline">
                        <h3>Outline</h3>
                        <div dangerouslySetInnerHTML={{ 
                            __html: DOMPurify.sanitize(formatTimestamps(outline.join('\n'))) 
                        }} />
                    </div>
                )}
                <div className="podcast-description-text">
                    <div dangerouslySetInnerHTML={{ 
                        __html: DOMPurify.sanitize(visibleDescription) 
                    }} />
                    {(mainDescription.length > 1 || mainDescription[0]?.length > 300) && (
                        <button 
                            onClick={() => setShowFullDescription(!showFullDescription)} 
                            className='note-expand-button'
                        >
                            {showFullDescription ? 'Show less' : 'Show more'}
                            {showFullDescription ? 
                                <FaAngleUp style={{marginLeft: '5px'}} /> : 
                                <FaAngleDown style={{marginLeft: '5px'}} />
                            } 
                        </button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className='podcast-player-container'>
            <div className='subject-rel-title' style={{marginTop: '0px'}}>Listen</div>
            <div className='podcast-player-wrapper'>
                <iframe 
                    src={embedInfo.embedUrl}
                    width="100%"
                    height={embedInfo.type === 'apple' ? "450" : "152"}
                    frameBorder="0" 
                    allowtransparency="true" 
                    allow="encrypted-media; autoplay; clipboard-write"
                    title="podcast-player"
                    style={{
                        borderRadius: '4px',
                        maxWidth: '100%'
                    }}
                ></iframe>
            </div>
            {description && (
                <>
                    <div className='subject-rel-title'>Description</div>
                    <div className='podcast-description'>
                        {formatDescription(description)}
                    </div>
                </>
            )}
        </div>
    );
};

export default PodcastPlayer; 