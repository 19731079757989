import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../Context/AppContext';
import CustomAvatar from '../CustomAvatar';
import NoteCard from '../NoteCard/NoteCard';
import ResourceCard from '../ResourceCard/ResourceCard';
import Loader from '../Loader';
import NoContentPlaceholder from './NoContentPlaceholder';
import { BiLike, BiSolidLike } from "react-icons/bi";
import { putLikeToFeed, profilePage, convertResourceType, DateFormatter } from '@linko/shared_utils';
import CustomLink from '../CustomLink';

const TabExplore = ({
    explore,
    setExplore,
    isFetchingMoreExplore,
    hasMoreExplore,
    resourceId,
    type
}) => { 
    
    const shareView = true; 
    const location = useLocation();
    const resourcePage = location.pathname.startsWith('/r/');
    const subjectsPage = location.pathname.startsWith('/s/');
    const homePage = location.pathname.startsWith('/my_linko');
    const { userInfo } = useContext(UserContext);

    explore = explore.map(item => {
        const liked = userInfo ? item.liked_by.some(user => user.id === userInfo.id) : false;
        let ExploreMessage = '';
        if (!item.note) {
            const resourceType = convertResourceType(item.resource.type);
            if (resourcePage) {
                if (item.resource.id.toString() === resourceId.toString()) {
                    ExploreMessage = `added this ${type} ${DateFormatter(item.create_time)}`;
                } else {
                    ExploreMessage = `added a related ${resourceType} ${DateFormatter(item.create_time)}`;
                }
            } else if (subjectsPage) {
                ExploreMessage = `added a related ${resourceType} ${DateFormatter(item.create_time)}`;
            } else if (homePage) {
                if (resourceType === 'article' || resourceType === 'online course') {
                    ExploreMessage = `added an ${resourceType} ${DateFormatter(item.create_time)}`;
                } else {
                    ExploreMessage = `added a ${resourceType} ${DateFormatter(item.create_time)}`;
                }
            }
        } else {
            if (resourcePage) {
                ExploreMessage = `took a note on this ${type} ${DateFormatter(item.create_time)}`;
            } else {
                ExploreMessage = "took a note " + DateFormatter(item.create_time);
            }
        }
        return {
            ...item,
            liked,
            ExploreMessage
        }
    });

    const handleLike = async (id, type) => {
        if (!userInfo) {
            console.warn('User must be logged in to like content');
            return;
        }
        try {
            putLikeToFeed(id, type);

            setExplore(prevExplore => prevExplore.map(item => {
                if (item.id.toString() === id) {
                    return {
                        ...item,
                        liked: !item.liked, 
                        liked_by: item.liked 
                        ? item.liked_by.filter(user => user.id !== userInfo.id) 
                        : [...item.liked_by, userInfo]
                    };
                }
                return item; 
            }));
        } catch (error) {
            console.error('Error liking content:', error);
        }

    }


    if (explore && explore.length === 0 && !isFetchingMoreExplore) {
        return (<NoContentPlaceholder activeTab={'explore'}/>);
    }

    return (
        <div className='explore-tab-content'>
            {explore && 
            <>
            {explore.map((item, index) => {
                const isNote = item.hasOwnProperty('note');
                const isFirstResource = !isNote && explore.slice(0, index).every(i => i.hasOwnProperty('note'));
                
                return (
                    <React.Fragment key={item.id}>
                        {isFirstResource && resourcePage && (
                            <div className="feed-resource-divider" style={{marginBottom:'10px'}}>Related resources</div>
                        )}
                        <div className="feed-group">
                            <div className='feed-group-title'>
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <CustomAvatar size={25} firstName={item.user.first_name} lastName={item.user.last_name}/>
                                    <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'flex-end', marginLeft:'6px', columnGap:'8px'}}>
                                        <div className='followee-name'>
                                            <CustomLink
                                                to={profilePage(item.user.id)}
                                            >
                                                {item.user.first_name} 
                                            </CustomLink>
                                        </div>
                                        <p className='feed-action-detail'>{item.ExploreMessage}</p>
                                    </div>
                                </div>
                                {item.liked ? 
                                    <BiSolidLike 
                                        className={`like-icon liked ${item.liked ? 'animate-like' : ''}`} 
                                        style={{color:'#257953'}} 
                                        onClick={() => handleLike(item.id, isNote ? `note` : `resource`)}
                                    />
                                    :
                                    <BiLike 
                                        className='like-icon' 
                                        onClick={() => handleLike(item.id, isNote ? `note` : `resource`)}
                                    />
                                }
                            </div>
                            {isNote ? 
                                <div className='cards-container'>
                                    <NoteCard 
                                        key={`N${item.id}`}
                                        note={item} 
                                        shareView={shareView} 
                                    />
                                </div>
                                :
                                <div className='cards-container'>
                                    <ResourceCard 
                                        key={`R${item.id}`}
                                        resource={item} 
                                        shareView={shareView} 
                                        style={{marginRight:'20px'}}
                                    />
                                </div>
                            }
                        </div>
                    </React.Fragment>
                );
            })}
            </>
            }
            {hasMoreExplore && isFetchingMoreExplore ? 
                <div className='tab-content-loader'>
                    <Loader/>
                </div>
                :
                <div style={{height:'10px'}}></div>
            }
            {!hasMoreExplore && explore.length >= 10 ? 
                <p style={{paddingLeft:'24px', fontStyle:'italic', margin:'30px 0'}}>No more content</p>
                :
                <div style={{height:'30px'}}></div>
            }
        </div>
    );
};

export default TabExplore;
