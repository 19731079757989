import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/AppContext';
import { FaChevronRight, FaExternalLinkAlt, FaRegCopy } from 'react-icons/fa';
import { useToast } from '../../Components/ToastNotification';
import { NameChangeModal, ResetPasswordModal } from './InputModals';
import { putUserData, profilePage } from '@linko/shared_utils';

const SettingPage = () => {
    const { userInfo, setUserInfo, fetchUserInfo } = useContext(UserContext);
    const { addToast } = useToast();
    const [isNameModalOpen, setIsNameModalOpen] = useState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

    const handleNameChange = () => {
        setIsNameModalOpen(true);
    };

    const handlePasswordReset = () => {
        setIsResetPasswordModalOpen(true);
    };

    const handlePrivacyChange = async (newValue) => {
        try {
            const response = await putUserData({ private_account: newValue });
            if (response.status === 200) {
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    private_account: newValue
                }));
                fetchUserInfo();
                addToast('Privacy settings updated successfully', { appearance: 'success' });
            } else {
                window.alert('Failed to update privacy settings');
            }
        } catch (error) {
            console.error('Failed to update privacy settings:', error);
            window.alert('Failed to update privacy settings');
        }
    };

    const handleNotePrivacyChange = async (newValue) => {
        try {
            const response = await putUserData({ private_note: newValue });
            if (response.status === 200) {
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    private_note: newValue
                }));
                fetchUserInfo();
                addToast('Privacy settings updated successfully', { appearance: 'success' });
            } else {
                window.alert('Failed to update privacy settings');
            }
        } catch (error) {
            console.error('Failed to update privacy settings:', error);
            window.alert('Failed to update privacy settings');
        }
    };

    const copyProfileLink = () => {
        if (navigator.clipboard) {
          const profileLink = `https://www.linko.study${profilePage(userInfo.id)}`;
          navigator.clipboard.writeText(profileLink)
            .then(() => {
                addToast('Profile link copied!', {appearance: 'success'});
            })
            .catch(err => {
              console.error('Error writing to clipboard', err);
              addToast('Failed to copy profile link', {appearance: 'error'});
            });
        } else {
          console.error('Clipboard API not supported');
            addToast('Failed to copy profile link', {appearance: 'error'});
        }
    }

    return (
        <div className="profile-page setting-page">
            <h1 style={{paddingLeft:'0px'}}>Settings</h1>
            <section className="account-section">
                <h2>Account</h2>
                <div className="setting-item">
                    <span>Email address</span>
                    <span className="secondary-text">{userInfo?.email}</span>
                </div>
                <div className="setting-item clickable" onClick={handleNameChange}>
                    <span>Name</span>
                    <span className="secondary-text">
                        {userInfo?.first_name} {userInfo?.last_name} 
                        <FaChevronRight className="chevron-icon"/>
                    </span>
                </div>
            </section>
            <div className='rx-divider-vertical' style={{margin:'20px 0'}}></div>
            <section className="security-section">
                <h2>Security</h2>
                <div className="setting-item clickable" onClick={handlePasswordReset}>
                    <span>Password</span>
                    <span className="secondary-text">
                        Reset password 
                        <FaChevronRight className="chevron-icon"/>
                    </span>
                </div>
            </section>
            <div className='rx-divider-vertical' style={{margin:'20px 0'}}></div>
            <section className="privacy-section">
                <h2>Privacy</h2>
                <div className="toggle-container">
                    <div>
                        <h3>Public mode</h3>
                        <p className="description">Your account is currently <strong>{userInfo?.private_account ? 'Private' : 'Public'}</strong></p>
                        <ul className="description">
                            <li>Private mode: All your content will be private. You will not be able to follow other users or be followed by others.</li>
                            <li>Public mode: Your selected public notes and resources will be visible to the public on your profile page. You will be able to follow other users and be followed by others.</li>
                        </ul>
                    </div>
                    <label className="toggle-switch">
                        <input 
                            type="checkbox" 
                            checked={!userInfo?.private_account || false} 
                            onChange={() => handlePrivacyChange(!userInfo?.private_account)}
                        />
                        <span className="toggle-slider"></span>
                    </label>
                </div>

                {/* Public account settings */}
                {!userInfo?.private_account && (
                    <>
                        <div className="setting-item sub-setting profile-link">
                            <span>Profile link</span>
                            <div className="link-container">
                                <span className="link" onClick={() => window.open(`https://www.linko.study${profilePage(userInfo?.id)}`, '_blank')}>
                                    https://www.linko.study{profilePage(userInfo?.id)}
                                </span>
                                <span className="copy-icon" onClick={copyProfileLink}>
                                    <FaRegCopy size={18}/>
                                </span>
                            </div>
                        </div>
                        <div className="setting-item toggle-container sub-setting">
                            <div>
                                <span>Default note privacy</span>
                                <p className="description">New notes will be {userInfo?.private_note ? 'private' : 'public'} by default</p>
                            </div>
                            <label className="toggle-switch">
                                <input 
                                    type="checkbox" 
                                    checked={userInfo?.private_note || false} 
                                    onChange={() => handleNotePrivacyChange(!userInfo?.private_note)}
                                />
                                <span className="toggle-slider"></span>
                            </label>
                        </div>
                    </>
                )}
            </section>

            <NameChangeModal 
                isOpen={isNameModalOpen} 
                onClose={() => setIsNameModalOpen(false)} 
            />
            <ResetPasswordModal 
                isOpen={isResetPasswordModalOpen} 
                onClose={() => setIsResetPasswordModalOpen(false)} 
            />
        </div>
    );
};

export default SettingPage;
