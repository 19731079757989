import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../Components/ToastNotification';
import { FollowContext, AuthContext } from '../../Context/AppContext';
import { GoPersonAdd } from "react-icons/go";
import { sendFollowRequest, handleFollowAction } from '@linko/shared_utils';
import { checkFollowStatus } from '../../utils/FollowStatusCheck';
import CustomAvatar from '../../Components/CustomAvatar';
import { useModal } from '../../Context/ModalContext';

const PublicProfileHeader = ({
    userId,
    userFirstName,
    userLastName,
    privateAccount,
}) => {
    
    const {requesting, followers, followings, fetchFollowData} = useContext(FollowContext);
    const {isAuthenticated} = useContext(AuthContext);
    const {requestConfirm} = useModal();
    const [friendStatus, setFriendStatus] = useState(null);
    const [followStatus, setFollowStatus] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonAction, setButtonAction] = useState('follow');
    const [confirmMessage, setConfirmMessage] = useState('');
    const [comparingDone, setComparingDone] = useState(false);
    const navigate = useNavigate();
    const {addToast} = useToast();

    useEffect(() => {
        try {
            const status = checkFollowStatus(userId, userFirstName, followers, followings, requesting);
            setFriendStatus(status.friendStatus);
            setFollowStatus(status.followStatus);
            setButtonDisabled(status.buttonDisabled);
            setButtonAction(status.buttonAction);
            setConfirmMessage(status.confirmMessage);
        } catch (error) {
            console.error(error);
        } finally {
            setComparingDone(true);
        }
    }, [requesting, userId, userFirstName, followers, followings]);

    const handleButtonClick = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (buttonAction === 'unfollow') {
            requestConfirm(
                confirmMessage, 
                async () => {
                try {
                    const response = await handleFollowAction(userId, 'unfollow');
                    if (response.status === 200) {
                        addToast('Successfully unfollowed user', {appearance: 'success'});
                        fetchFollowData();
                    } else {
                        window.alert('Failed to unfollow user, please try again.');
                    }
                } catch (error) {
                    console.error(error);
                        window.alert('Failed to unfollow user, please try again.');
                    }
                },
                null,
                'Unfollow'
            );
        } else if (buttonAction === 'follow') {
            try {
                const response = await sendFollowRequest(userId);
                if (response.status === 200) {
                    addToast('Follow request sent', {appearance: 'success'});
                    fetchFollowData();
                } else {
                    window.alert('Failed to send follow request, please try again.');
                }
            } catch (error) {
                console.error(error);
                window.alert('Failed to send follow request, please try again.');
            }
        }
    };

    return (
        <div className='profile-left-header'>
            {userFirstName && comparingDone &&
                <>
                    <div className='header-group'>
                        <CustomAvatar size={45} firstName={userFirstName} lastName={userLastName} />
                        <div className='user-name-group'>
                            <h1 className='subject-name'>{userFirstName} {userLastName}</h1>
                            <p>{friendStatus}</p>
                        </div>
                        {!privateAccount && (
                            <button 
                                className={`add-friend-icon linko-button linko-button--tertiary ${buttonDisabled ? 'linko-button--tertiary-outline' : ''}`} 
                                title={buttonAction === 'follow' ? 'Request follow' : buttonAction === 'unfollow' ? 'Unfollow' : 'Pending'} 
                                onClick={handleButtonClick}
                                disabled={buttonDisabled}
                            >
                                <GoPersonAdd />
                                <span>{followStatus}</span>
                            </button>
                        )}
                    </div>
                </>
            }
        </div>
    );
};

export default PublicProfileHeader;