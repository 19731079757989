import React, {useState, useEffect, useContext, useRef} from "react";
import { useToast } from "../ToastNotification";
import { UserContext } from "../../Context/AppContext";
import { HomePageContext } from '../../Context/HomePageContext';
import { useLocation } from 'react-router-dom';
import ResourceCardActions from "./ResourceCardActions";
import ResourceTypeIcon from "./ResourceTypeIcon";
import ResourceProgress from "./ResourceProgress";
import CardSubject from "../CardSubject";
import LearnedUsers from "./LearnedUsers";
import { postResourceToMine, convertResourceType, useWindowSize } from "@linko/shared_utils";
import CustomLink from "../CustomLink";
import { MdOutlineLibraryAdd } from "react-icons/md";
import ResourcePageRightTabs from "../../Pages/ResourcePage/Tabs";

const ResourceCard = ({ 
    resource, 
    onDelete, 
    onUpdate, 
    shareView, 
    currentResource,
    learnedUserData,
    articleContent,
    embeddable,
    authorInfo,
    recommendations
}) => {
    const location = useLocation();
    let id, title, author, type, description, month, year, url, platform, image_link;
    if (resource && resource.resource) {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource.resource);
    } else {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource);
    }
    const { addResourceToLibrary } = useContext(HomePageContext);
    const resourceCardRef = useRef(null);
    const [subject, setSubject] = useState([]);
    const isBook = type === "bo";
    const imageLink = image_link && image_link.trim() !== "" 
        ? image_link.startsWith("http:")
            ? "https:" + image_link.substring(5)
            : image_link
        : null;
    const { userInfo, userResourceCount, setUserResourceCount } = useContext(UserContext);
    const { addToast, setPersistentMessage } = useToast();
    const [imageError, setImageError] = useState(false);
    const learnedUserCount = learnedUserData?.following_user_learned?.length + learnedUserData?.public_user_learned?.length + learnedUserData?.private_user_learned_count;

    useEffect(() => {
        if (resource?.user !== null) {
            setSubject(resource.user_knowledge);
        } else {
            setSubject(resource.resource.knowledge);
        }
    }, [resource, userInfo]);

    const handleAddToLibrary = async () => {
        const response = await postResourceToMine(id);
        if (response.status === 201) {
            if (currentResource) { 
                window.location.reload(); 
                setPersistentMessage("Resource added to library");
            } else {
                addToast("Resource added to library");
            }
            addResourceToLibrary(response.data.resource);
        } else {
            window.alert("Failed to add resource to library, please try again.");
        }
        if (userResourceCount === 0) {
            setUserResourceCount(1);
        }
    }

    return (
        <div 
            id="resource-card" 
            ref={resourceCardRef}
            style={{ minHeight: currentResource ? '200px' : '230px'}}
        >
            <div className="card-header">
                <div className="card-header-left">
                    <div className="header-group">
                        <ResourceTypeIcon type={type} size={18}/>
                        <p className="type-text">{convertResourceType(type)}</p>
                    </div>
                    {learnedUserData && learnedUserCount > 1 && <div className="header-group"> • </div>}
                    {learnedUserData && learnedUserCount > 1 &&
                        <div className="header-group">
                            <LearnedUsers learnedUserData={learnedUserData}/>
                        </div>
                    }
                </div>
                <ResourceCardActions
                    link={url}
                    id={id}
                    onDelete={onDelete}
                    isBook={isBook}
                    shareView={shareView}
                    handleAddToLibrary={handleAddToLibrary}
                    currentResource={currentResource}
                />
            </div>
            <div className="resource-info">
                <div className="text-image-info">
                    <div className="resource-title-author">
                        <CustomLink
                            to={`/r/${id}`}
                            className={currentResource ? 'no-hover' : 'resource-title'}
                        >
                            {title}
                        </CustomLink>
                        <div className="author" style={{columnGap:'0px'}}>
                            {author && <span>{author}</span>}
                            {author && year && <span>, {year}</span>}
                            {author && year && month && <span>.{month.toString().padStart(2, '0')}</span>}
                            {platform && platform !== 'null' && <span> , {platform} </span> }
                        </div>
                        {!shareView && <ResourceProgress id={id} learnHistory={resource} onUpdate={onUpdate}/>
                        }
                    </div>
                    {imageLink !== null && !imageError && (
                        <div className="resource-image">
                            <img
                                src={imageLink}
                                alt={title}
                                onError={() => setImageError(true)}
                            />
                        </div>
                    )}
                </div>
                <CardSubject
                    subject={subject}
                    setSubject={setSubject}
                    shareView={shareView}
                    resource={resource}
                    resourceId={id}
                    onUpdate={onUpdate}
                />
            </div>
        </div>
    );
};

export default ResourceCard;
