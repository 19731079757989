import React, { useContext } from 'react';
import { HomePageContext } from '../../Context/HomePageContext';
import { UserContext } from '../../Context/AppContext';
import NoteCard from '../NoteCard/NoteCard'; 
import { getSingleNote } from '@linko/shared_utils';
import { LuArrowRight } from "react-icons/lu";
import Loader from '../Loader';

const TabRevisit = ({ }) => {

  const { revisitSubject, revisitNote, setRevisitNote, isShufflingSubject, setIsShufflingSubject } = useContext(HomePageContext);
  const { userNoteCount } = useContext(UserContext);
  const handleShuffle = () => {
    window.scrollTo(0, 0);
    setIsShufflingSubject(true);
  };
  
  const deleteNote = (noteId) => {
    setRevisitNote(revisitNote.filter(note => note.id !== noteId));
  };

  const updateSingleNote = (noteId) => {
    getSingleNote(noteId)
    .then((response) => {
        const updatedNote = response.data.note;
        setRevisitNote(revisitNote.map(note => note.id === noteId ? updatedNote : note));
    })
    .catch((error) => {
        console.error('Error fetching note:', error.response ? error.response.data : error.message);
    });
  }

  return (
    <div style={{width:'100%'}}>
      {!isShufflingSubject && revisitSubject?.qa && (
        <div className="revisit-qa">
          <div className="qa-question">
            <span className="qa-label">Q:</span>
            <span className="qa-text">{revisitSubject.qa.question}</span>
          </div>
          <div className="qa-answer">
            <span className="qa-label">A:</span>
            <span className="qa-text">{revisitSubject.qa.answer}</span>
          </div>
        </div>
      )}
      {isShufflingSubject ? 
        <div className='tab-content-loader'><Loader size={50}/></div>
        :
        <>
          <div className="cards-container">
            {revisitNote && revisitNote.map((note) => (
              <div className="revisit-note" key={note.id} style={{width:'100%'}}>
                <NoteCard 
                  key={note.id}
                  note={note}
                  onDelete={deleteNote}
                  shareView={false}
                  subjectId={null}
                  updateSingleNote={updateSingleNote}
                  revisitTab={true}
                />
              </div>
            ))}
          </div>
          {userNoteCount !== 0 && (
            <div className="see-next-button-container">
              <button 
                className="linko-button linko-button--tertiary see-next-button" 
                onClick={handleShuffle}
                disabled={isShufflingSubject}
              >
                See Next <LuArrowRight size={18} style={{marginLeft: '4px'}}/>
              </button>
            </div>
          )}
        </>
      }
    </div>
  );
};

export default TabRevisit;
