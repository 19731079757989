import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// Components
import NoteCard from '../NoteCard/NoteCard';
import Loader from '../Loader';
import NoContentPlaceholder from './NoContentPlaceholder';
import { getSingleNote } from '@linko/shared_utils';

const TabNotes = ({
    subjectId, 
    notes,
    setNotes,
    setLibrary,
    isFetchingMoreNotes,
    hasMoreNotes,
}) => {

    const location = useLocation();
    const shareView = false;
    const isLibraryView = useMemo(() => location.pathname.startsWith('/my_library'), [location.pathname]);
    const isNotePage = useMemo(() => location.pathname.startsWith('/n/'), [location.pathname]);

    const deleteNote = useCallback((noteId) => {
        setNotes?.(prevNotes => prevNotes.filter(note => note.id !== noteId));
        setLibrary?.(prevLibrary => prevLibrary.filter(item => item.id !== noteId));
    }, [setNotes, setLibrary]);

    // const updateNoteContent = useCallback((updatedNote) => {
    //     const updateFunc = item => item.id === updatedNote.id ? updatedNote : item;
    //     setNotes?.(prevNotes => prevNotes.map(updateFunc));
    //     setLibrary?.(prevLibrary => prevLibrary.map(updateFunc));
    // }, [setNotes, setLibrary]);

    const updateSingleNote = useCallback((noteId) => {
        getSingleNote(noteId)
            .then(({ data: { note: updatedNote } }) => {
                setNotes?.(prevNotes => prevNotes.map(note => note.id === noteId ? updatedNote : note));
                setLibrary?.(prevLibrary => prevLibrary.map(item => item.id === noteId ? updatedNote : item));
            })
            .catch((error) => {
                console.error('Error fetching note:', error.response?.data || error.message);
            });
    }, []);

    const renderNotes = useMemo(() => (
        notes?.map(note => (
            <NoteCard
                key={note.id}
                note={note}
                onDelete={deleteNote}
                // onUpdate={updateNoteContent}
                shareView={shareView}
                subjectId={subjectId}
                updateSingleNote={updateSingleNote}
            />
        ))
    ), [notes, deleteNote, shareView, subjectId, updateSingleNote]);

    const renderContent = () => {

        return (
            <div>
                <div className={isLibraryView ? 'library-cards-container' : 'cards-container'} >
                    {renderNotes}
                </div>
                {hasMoreNotes && isFetchingMoreNotes ? (
                    <div className='tab-content-loader' style={{width:'620px'}}><Loader/></div>
                ) : (
                    <div style={{height:'10px'}}></div>
                )}
                {!isFetchingMoreNotes && notes?.length === 0 && location.pathname.startsWith('/n/') && (
                    <NoContentPlaceholder activeTab='notes'/>
                )}
                {!hasMoreNotes && notes?.length >= 10 ? (
                    <p style={{paddingLeft:'24px', fontStyle:'italic', margin:'30px 0'}}>No more notes</p>
                ) : (
                    <div style={{height:'30px'}}></div>
                )}
            </div>
        );
    };

    return renderContent();
};

export default React.memo(TabNotes);
