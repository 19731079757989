import React, {useState, useEffect, useRef, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import { searchAll } from '@linko/shared_utils';

const Search = () => {
    // const [users, setUsers] = useState([]);
    const [resources, setResources] = useState([]);
    const [notes, setNotes] = useState([]);
    const [subject, setSubject] = useState({ myTag: [], studied: [], notStudied: [] }); 
    const [isFocused, setIsFocused] = useState(true); 
    const [searchArea, setSearchArea] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [isLoading, setIsLoading] = useState(false);
    const [closeAllowed, setCloseAllowed] = useState(true);
    const timeoutIdOne = useRef(); 
    const searchResultsRef = useRef(null); 
    const sectionTitleRefs = useRef([]);
    sectionTitleRefs.current = [useRef(null), useRef(null), useRef(null)];
    const location = useLocation();
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.getAttribute('id');
                    if (entry.isIntersecting) {
                        entry.target.classList.add('sticky');
                    } else {
                        entry.target.classList.remove('sticky');
                    }
                });
            },
            { 
                threshold: 0.1 
            } 
        );

        sectionTitleRefs.current.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Clean up
        return () => {
            sectionTitleRefs.current.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (searchTerm.length >= 2) {
                setIsLoading(true);
                timeoutIdOne.current && clearTimeout(timeoutIdOne.current);
                timeoutIdOne.current = setTimeout(async () => {
                    try {
                        const data = await searchAll(searchTerm);
                        
                        const myTagResults = data.knowledge.filter(subject => !subject.is_linked);
                        const studiedResults = data.knowledge.filter(subject => subject.studied);
                        const notStudiedResults = data.knowledge.filter(subject => !subject.studied);

                        setSubject({
                            myTag: myTagResults,
                            studied: studiedResults,
                            notStudied: notStudiedResults
                        });
                        setResources(data.resources);
                        setNotes(data.notes);
                        // setUsers(data.users);
                        setIsLoading(false);
                    } catch (error) {
                        console.error('Error fetching search results:', error);
                        setIsLoading(false);
                    }
                }, 1000);
            } else {
                setSubject({myTag: [], studied: [], notStudied: []});
                setResources([]);
                setNotes([]);
                // setUsers([]);
            }
        };
        fetchData();
    }, [searchTerm]);

    useEffect(() => {
        setSearchTerm('');
    }, [location]);

    useEffect(() => {
        setSearchArea(false);
    }
    , [location]);


    return (
        <div id="search" >
            <form method="get" action="/search" id='search-form'>
                <SearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setIsFocused={setIsFocused}
                    setCloseAllowed={setCloseAllowed}
                    isFocused={isFocused}
                    setSearchArea={setSearchArea}
                    searchArea={searchArea}
                    searchResultsRef={searchResultsRef}
                />
                {isFocused && searchTerm !== '' && (
                    <SearchResults
                        isLoading={isLoading}
                        // users={users}
                        resources={resources}
                        notes={notes}
                        subject={subject}
                        isFocused={isFocused}
                        searchTerm={searchTerm}
                        setCloseAllowed={setCloseAllowed}
                    />
                )}
            </form>
        </div>
    );
};

export default Search;
