import React, { useState, useContext } from 'react';
import TabDataWrapper from '../../Components/ContentTabs/TabDataWrapper';
import HomePageRight from './HomePageRight';
import { SidebarContext } from '../../Context/AppContext';

const HomePage = () => {
  const [showTextArea, setShowTextArea] = useState(true);
  const { showSidebar } = useContext(SidebarContext);

  return (
    <div className='page-container' style={{columnGap: showSidebar ? '5vw' : '0'}}>
      <div className='home-page-left'>
        <TabDataWrapper showTextArea={showTextArea} setShowTextArea={setShowTextArea} />
      </div>
      <div className={`home-page-right-wrapper ${showSidebar ? 'sidebar-visible' : 'sidebar-hidden'}`}>
        <HomePageRight />
      </div>
    </div>
  );
};

export default HomePage;