import React, { useState, useEffect } from 'react';
import RichEditor from './RichEditor';
import LinkedResource from './LinkedResource';
import { CgCompressRight } from "react-icons/cg";
import Loader from '../Loader';
import Tippy from '@tippyjs/react';
import PrivateToggle from './PrivateToggle';

const FocusMode = ({
    handleSubmit,
    title,
    handleTitleChange,
    handleEnterKey,
    handleTitlePaste,
    setIsFullScreen,
    isPrivate,
    handlePrivateChange,
    handleCancel,
    isLoading,
    note,
    handleEditorChange,
    editorContent,
    canExitFocusMode = true,
    noteResource,
    setNoteResource,
    updateSingleNote,
}) => {

    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        setIsTouchDevice(isTouch);
    }, []);

    return (
        <div className="focus-mode">
            <div className='focus-mode-header'>
                <LinkedResource 
                    noteResource={noteResource}
                    setNoteResource={setNoteResource}
                    noteId={note?.id}
                    updateSingleNote={updateSingleNote}
                />
                <div className='cancel-or-submit'>
                    <PrivateToggle isPrivate={isPrivate} handlePrivateChange={handlePrivateChange} />
                    <button onClick={handleCancel} className='linko-button linko-button--cancel' style={{marginRight:'5px'}} disabled={isLoading}>Cancel</button>
                    <button onClick={handleSubmit} className='linko-button linko-button--cancel' disabled={isLoading}>Save</button>
                </div>
            </div>
            {isLoading ? <Loader /> :
                <form onSubmit={handleSubmit}>
                    <div className='add-note-card-header'>
                        <textarea 
                        value={title} 
                        onChange={handleTitleChange}
                        onKeyDown={handleEnterKey} 
                        onPaste={handleTitlePaste}
                        placeholder='Title' 
                        className='add-note-title'
                        rows={1}  
                        />
                        {canExitFocusMode && (
                            <Tippy 
                                content="Quit focus mode"
                                placement="bottom"
                                arrow={true}
                                theme='light-border'
                                hideOnClick={true} 
                                delay={[0, 0]} 
                                touch={false}
                                disabled={isTouchDevice}
                            >
                            <div>
                                <CgCompressRight 
                                title='Exit focus mode'
                                onClick={() => setIsFullScreen(false)}
                                />
                            </div>
                            </Tippy>
                        )}
                    </div>  
                    <RichEditor 
                        key={note?.id}
                        value={editorContent}
                        onChange={handleEditorChange} 
                        placeholder='Write your note...'
                    />
                </form>
            }
        </div>
    );
};

export default FocusMode;
